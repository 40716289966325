@charset "UTF-8";
@import "variables";
@import "mixins";

.navigation.archivenav {
  @include topmargin(large);
  .inner {
    @include container;
    .overflow {
      overflow: auto;
      padding: 0 0 10px;
      @include mq(tbl) {
        overflow: visible;
        padding: 0;
      }
      ul {
        display: flex;
        justify-content: flex-start;
        border-bottom: 2px solid $main;
        @include mq(tbl) {
          flex-wrap: wrap;
          border-bottom-width: 3px;
        }
        li {
          @include fs(12,12);
          white-space: nowrap;
          margin-left: 20px;
          position: relative;
          &:first-child {
            margin-left: 0;
          }
          @include mq(tbl) {
            @include fs(14,14);
            margin-left: 20px;
          }
          &.active,&.current_page_item {
            z-index: 2;
            a {
              color: $white;
              background-color: $main;
              &:before {
                background-color: $white;
                left: 0;
                right: 0;
              }
              &:hover {
                @include mq(tbl) {
                  @include hoverOp(1);
                }
              }
            }
          }
          a {
            display: block;
            position: relative;
            background-color: $lightgray;
            border-radius: $radius-small $radius-small 0 0;
            padding: 11px 28px;
            color: $black;
            letter-spacing: 0;
            font-weight: $font-weight-bold;
            transition: color $transition-speed $bezier;
            @include mq(tbl) {
              padding: 18px 46px;
              border-radius: $radius-default $radius-default 0 0;
            }
            /*
            &:before {
              @include pd;
              background-color: #707070;
              height: 1px;
              bottom: -1px;
              left: -1px;
              right: -1px;
            }
            */
            &:hover {
              @include mq(tbl) {
                @include hoverOp;
              }
            }
          }
        }
      }
    }
  }
}
