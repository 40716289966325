@charset "UTF-8";
@import "variables";
@import "mixins";

.component.type_wysiwyg {
  @include topmargin;
  .inner {
    @include container;
  }
}
