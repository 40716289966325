@charset "UTF-8";
@import "variables";
@import "mixins";

.component.type_anchor {
  @include topmargin(large);
  .inner {
    @include container;
    ul {
      @include grid(2,20px,15px);
      @include mq(tbl) {
        @include grid(5,20px,20px);
      }
      li {
        @include fs(13,20);
        @include mq(tbl) {
          @include fs(15,25);
        }
        a {
          display: block;
          position: relative;
          text-decoration: none;
          color: $main-light;
          padding-left: 20px;
          transition: color $transition-speed-fast $bezier;
          &:before {
            @include pd;
            height: 6px;
            width: 10px;
            left: 0;
            top: 7px;
            background-image: url(#{$imagepath}arrow_bottom.svg);
            background-repeat: no-repeat;
            background-size: contain;
            @include mq(tbl) {
              top: 10px;
            }
          }
          &:hover {
            @include mq(tbl) {
              color: $main;
            }
          }
        }
      }
    }
  }
}
