@import "variables";

@mixin pd {
  position: absolute;
  content: '';
  display: block;
}
@mixin mq($breakpoint: tbl) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}
@mixin sp_pc {
  &.sp {
    @include mq(tbl) {
      display: none;
    }
  }
  &.pc {
    display: none;
    @include mq(tbl) {
      display: block;
    }
  }
}
@mixin container($sp:26px,$type:normal) {
  padding-left: $sp;
  padding-right: $sp;
  @include mq(tbl) {
    padding-left: calc(50% - (#{$container} / 2));
    padding-right: calc(50% - (#{$container} / 2));
  }
  @if $type == wide {
    @include mq(pc) {
      padding-left: calc(50% - (#{$container-wide} / 2));
      padding-right: calc(50% - (#{$container-wide} / 2));
    }
  }
  @if $type == narrow {
    @include mq(pc) {
      padding-left: calc(50% - (#{$container-narrow} / 2));
      padding-right: calc(50% - (#{$container-narrow} / 2));
    }
  }
}
@mixin title {
  @include fs(18,26);
  padding: 0 0 12px;
  border-bottom: 1px solid #ccc;
  font-weight: $font-weight-bold;
  font-family: $font-family-sub;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 8px;
  @include mq(tbl) {
    @include fs(25,35);
    padding: 0 0 14px 0;
    gap: 10px;
  }
  &:before {
    content: '';
    width: 14px;
    height: 14px;
    background-color: #E35329;
    z-index: 2;
    border-radius: 14px;
    translate: 0 7px;
    @include mq(tbl) {
      width: 18px;
      height: 18px;
      translate: 0 10px;
    }
  }
}
@mixin button($size:medium) {
  display: inline grid;
  grid-template-columns: auto 1fr auto;
  gap: 10px;
  align-items: center;
  @include fs(15,25);
  padding: 10px 16px;
  text-decoration: none !important;
  font-weight: $font-weight-bold;
  position: relative;
  color: $white;
  background-color: $main;
  border-radius: 5px;
  min-width: 150px;
  text-align: center;
  @include mq(tbl) {
    @include hoverOp(.8);
    @include fs(16,28);
    padding: 16px 22px;
    min-width: 200px;
  }
  &::before {
    content: '';
    display: block;
    width: 9px;
  }
  &::after {
    content: '';
    display: block;
    background: center / contain no-repeat url(#{$imagepath}arrow_right_main.svg);
    width: 9px;
    height: 13px;
  }
  @if $size == small {
    @include fs(12,18);
    @include mq(tbl) {
      @include fs(14,21);
    }
    &::after {
      width: 7px;
      height: 11px;
    }
  }
  @else if $size == reverse {
    grid-template-columns: auto 1fr;
    &::before {
      background: center / contain no-repeat url(#{$imagepath}arrow_right_main.svg);
      height: 13px;
      rotate: z 180deg;
    }
    &::after {
      background-image: unset;
      content: none;
    }
  }
  @else if $size == blank {
    &::before {
      width: 22px;
    }
    &::after {
      background-image: url(#{$imagepath}arrow_newwin.svg);
      height: 22px;
      width: 22px;
    }
  }
  @else if $size == apply {
    background-color: $red;
    padding: 12px 35px;
    min-width: 300px;
    @include fs(18,26);
    color: $white;
    border-radius: $radius-default;
    font-weight: $font-weight-bold;
    &::after {
      content: none;
    }
    @include mq(tbl) {
      @include hoverOp(.8);
      padding: 20px 35px;
      min-width: 340px;
    }
  }
}
@mixin hoverOp($op:0.7) {
  transition: opacity .3s $bezier;
  &:hover {
    opacity: $op;
  }
}
@mixin fs($fts, $lin: 14) {
  $rem: $fts / 10;
  $linS: $lin / $fts;
  font-size: #{$fts}px;
  font-size: #{$rem}rem;
  line-height: $linS;
}
@mixin slickdots($width:8px,$height:8px) {
  text-align: center;
  li {
    display: inline-block;
    width: auto;
    padding: 0;
    margin: 0 5px;
    button {
      border: none;
      background-color: #ebebeb;
      color: transparent;
      height: $height;
      width: $width;
      padding: 0;
      cursor: pointer;
      border-radius: 0;
    }
    &.slick-active {
      button {
        background-color: $main;
      }
    }
  }
}
@mixin topmargin($size:medium){
  margin-top: 25px;
  @include mq(tbl) {
    margin-top: 40px;
  }
  @if $size == small {
  }
  @else if $size == large {
    margin-top: 35px;
    @include mq(tbl) {
      margin-top: 50px;
    }
  }
  @else if $size == xlarge {
    margin-top: 40px;
    @include mq(tbl) {
      margin-top: 60px;
    }
  }
  @else if $size == xxlarge {
    margin-top: 60px;
    @include mq(tbl) {
      margin-top: 80px;
    }
  }
  @else if $size == xxxlarge {
    margin-top: 80px;
    @include mq(tbl) {
      margin-top: 100px;
    }
  }
}

@mixin text($size:medium){
  @include fs(15,26);
  font-family: $font-family-main;
  @include mq(tbl) {
    @include fs(16,30);
  }
  @if $size == xsmall {
    @include fs(11,16);
    @include mq(tbl) {
      @include fs(12,18);
    }
  }
  @if $size == small {
    @include fs(12,18);
    @include mq(tbl) {
      @include fs(15,25);
    }
  }
  @if $size == medium {
    padding-bottom: 1.5em;
  }
  @if $size == large {
    @include fs(16,26);
    @include mq(tbl) {
      @include fs(18,28);
    }
  }
  @if $size == caption {
    @include fs(12,18);
    font-weight: $font-weight-normal;
    @include mq(tbl) {
      @include fs(13,21);
    }
  }
  @else if $size == heading {
    @include fs(22,32);
    font-weight: $font-weight-bold;
    @include mq(tbl) {
      @include fs(25,38);
    }
  }
}

@mixin grid($cols,$gap-vert,$gap-horiz) {
  display: grid;
  grid-template-columns: repeat($cols, 1fr);
  grid-template-rows: repeat(1, auto);
  grid-gap: $gap-vert $gap-horiz;
}