@charset "UTF-8";
@import "variables";
@import "mixins";

.component.type_panel {
  @include topmargin(large);
  + .component.type_panel {
    @include mq(tbl) {
      // margin-top: 0;
    }
  }
  &.set2 {
    .inner {
      @include grid(1,50px,0);
      @include mq(tbl) {
        @include grid(2,60px,54px);
      }
    }
  }
  &.set3 {
    .inner {
      @include grid(2,50px,25px);
      @include mq(tbl) {
        @include grid(3,50px,25px);
      }
      .unit {
        .title {
          @include mq(tbl) {
            margin-top: 22px;
          }
          h3 {
            @include mq(tbl) {
              @include fs(16,28);
            }
          }
        }
      }
    }
  }
  &.set4 {
    .inner {
      @include grid(2,50px,25px);
      @include mq(tbl) {
        @include grid(4,40px,20px);
      }
      .unit {
        .title {
          @include mq(tbl) {
            margin-top: 22px;
          }
          h3 {
            @include mq(tbl) {
              @include fs(16,28);
            }
          }
        }
        .desc {
          @include mq(tbl) {
            @include fs(14,24);
          }
        }
      }
    }
  }
  .inner {
    @include container;
    @include mq(tb) {
      display: flex;
      justify-content: flex-start;
      gap: 25px;
    }
    .unit {
      @include mq(tb) {
        max-width: 460px;
        flex: 1;
        position: relative;
      }
      .photo {
        @include mq(tb) {
          display: flex;
          align-items: center;
        }
        a {
          @include mq(tbl) {
            @include hoverOp;
          }
        }
        img {
          border-radius: 5px;
        }
      }
      .title {
        margin-top: 16px;
        @include mq(tbl) {
          margin-top: 20px;
        }
        h3 {
          @include fs(16,26);
          font-weight: $font-weight-bold;
          @include mq(tbl) {
            @include fs(18,28);
          }
          a {
            text-decoration: none;
            color: $black;
            @include mq(tbl) {
              @include hoverOp;
            }
          }
        }
      }
      .desc {
        @include fs(14,24);
        margin-top: 8px;
        @include mq(tbl) {
          margin-top: 10px;
          @include fs(16,26);
        }
      }
      .button {
        margin-top: 15px;
        text-align: right;
        @include mq(tbl) {
          margin-top: 25px;
        }
        a {
          @include button(small);
        }
      }
    }
  }
}
