@charset "UTF-8";
@import "variables";
@import "mixins";

.component.type_iframe{
  @include topmargin(large);
  .inner {
    @include container;
    text-align: center;
    iframe {
      max-width: 100%;
    }
  }
}
