$container: 980px;
$container-narrow: 840px;
$container-wide: 1180px;

$main: #2F4A5B;
$white: #fff;
$red: #AC2C31;
$black: #333;
$main-dark:$main;
$main-light:#757B80;
$sub:#F2F4F3;
$sub-dark:$sub;
$third:#F0ECE2;
$lightgray: #E6E6E6;

$border: $lightgray;

$link-default:$main-light;
$body-text-color: $black;

$radius-xsmall:0px;
$radius-small:4px;
$radius-default: 5px;
$radius-large: 0px;

// transitions settings

$bezier: cubic-bezier(.25,.1,.25,1);
$transition-speed: .5s;
$transition-speed-fast: .3s;

// font settings

$font-family-default: "Zen Kaku Gothic New", sans-serif;
$font-family-main: "Zen Kaku Gothic New", sans-serif;
$font-family-sub: "Zen Kaku Gothic New", sans-serif;
$font-family-en: Helvetica, Arial ,sans-serif;

$font-weight-normal: 400;
$font-weight-medium: 400;
$font-weight-bold: 700;

// debug settings

$responsive-debug-mode: false;

// header settings

$header-height: 60px;
$header-height-pc: 90px;

// store icon settings

$header-store:true;
$header-store-width:$header-height;

// menu button settings

$menu-bar-width: 30px;

$imagepath: '/images/';

$breakpoints: (
  'min': 'print, screen and (max-width: 374px)',
  'sp': 'print, screen and (min-width: 375px)',
  'spl': 'print, screen and (max-width: 768px) and (orientation:landscape)',
  'tb': 'print, screen and (min-width: 768px)',
  'tbl': 'print, screen and (min-width: 1080px)',
  'pc': 'print, screen and (min-width: 1280px)',
) !default;
