@charset "UTF-8";
@import "variables";
@import "mixins";

.footbanner {
  margin-top: 100px;
  @include mq(tbl) {
    display: flex;
    margin-top: 150px;
  }
  .localnav_bottom + & {
    margin-top: 0;
  }
  .toppage.articlelist + & {
    margin-top: 0;
  }
  .breadcrumbs + & {
    @include mq(tbl) {
      margin-top: 0;
    }
  }
  .unit {
    padding: 34px 26px;
    @include mq(tbl) {
      flex: 1;
      padding: 60px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    &.download {
      background: center / cover url(/images/bg_download.jpg);
    }
    &.contact {
      background: center / cover url(/images/bg_contact.jpg);
    }
    h2 {
      text-align: center;
      color: $white;
      @include fs(15,24);
      font-weight: $font-weight-bold;
      @include mq(tbl) {
        @include fs(20,34);
      }
    }
    .button {
      margin-top: 22px;
      @include mq(tbl) {
        margin-top: 30px;
      }
      a {
        border-radius: 4px;
        padding: 17px 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $white;
        font-weight: $font-weight-bold;
        max-width: 480px;
        margin-left: auto;
        margin-right: auto;
        transition: background-color $transition-speed-fast $bezier;
        @include mq(tbl) {
          padding: 25px;
          border-radius: 5px;
        }
        i {
          margin-right: 17px;
          svg {
            width: 28px;
            fill: $white;
          }
        }
        span {
          @include fs(15,15);
          @include mq(tbl) {
            @include fs(18,18);
          }
        }
      }
      &.download {
        a {
          background-color: $main;
          &:hover {
            background-color: lighten($main, 10%);
          }
        }
      }
      &.contact {
        a {
          background-color: #E35329;
          &:hover {
            background-color: lighten(#E35329, 15%);
          }
        }
      }
      &.tel {
        text-align: center;
        margin-top: 15px;
        @include mq(tbl) {
          margin-top: 20px;
        }
        a {
          background-color: $white;
          display: block;
          color: $black;
          padding-top: 10px;
          padding-bottom: 8px;
          @include mq(tbl) {
            display: flex;
            padding: 20px;
          }
          .num {
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: $font-family-en;
            i {
              margin-right: 10px;
              @include mq(tbl) {
                margin-right: 15px;
              }
              img {
                width: 20px;
                transform: translateY(-2px);
                @include mq(tbl) {
                  width: 28px;
                  transform: translateY(-1px);
                }
              }
            }
            span {
              @include fs(24,24);
              @include mq(tbl) {
                @include fs(28,28);
              }
            }
          }
          .hours {
            color: #54565A;
            @include fs(12,18);
            font-weight: $font-weight-normal;
            @include mq(tbl) {
              @include fs(13,18);
              margin-left: 20px;
            }
          }
        }
      }
    }
  }
}
.uniq.cattitle {
  margin-top: 22px;
  .inner {
    @include container(0,wide);
  }
}
.uniq.itemList {
  @include topmargin(large);
  .inner {
    @include container;
    .count {
      @include fs(14,24);
      padding-top: 14px;
      font-weight: $font-weight-bold;
      @include mq(tbl) {
        @include fs(16,27);
        padding-top: 0;
      }
      + .list {
        margin-top: 20px;
        @include mq(tbl) {
          margin-top: 30px;
        }
      }
    }
    .list {
      @include grid(2,50px,25px);
      @include mq(tbl) {
        @include grid(3,50px,25px);
      }
      .unit {
        display: grid;
        grid-template-rows: subgrid;
        grid-row: span 2;
        gap: 0;
        .image {
          a {
            @include mq(tbl) {
              @include hoverOp;
            }
          }
          img {
            border-radius: 5px;
            width: 100%;
          }
        }
        .desc {
          margin-top: 16px;
          @include mq(tbl) {
            margin-top: 20px;
          }
          h3 {
            @include fs(16,26);
            font-weight: $font-weight-bold;
            @include mq(tbl) {
              @include fs(16,28);
            }
            a {
              text-decoration: none;
              color: $black;
              @include mq(tbl) {
                @include hoverOp;
              }
            }
          }
          .summary {
            @include fs(14,24);
            margin-top: 8px;
            @include mq(tbl) {
              margin-top: 10px;
              @include fs(16,26);
            }
          }
        }
      }
    }
  }
}
.uniq.noResults {
  display: none;
  .inner {
    @include container;
    text-align: center;
    height: calc(100svh - 300px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 120px;
    @include mq(tbl) {
      height: auto;
    }
    p {
      @include fs(14,24);
      @include mq(tbl) {
        @include fs(16,26);
      }
    }
    button {
      margin-top: 20px;
      @include button(reverse);
      @include mq(tbl) {
        margin-top: 40px;
      }
    }
  }
}