@charset "UTF-8";
@import "variables";
@import "mixins";

.navigation.articlelist {
  @include topmargin(large);
  &.bg {
    background-color: #F3F5F6;
    padding-top: 60px;
    padding-bottom: 60px;
    margin-top: 0;
    @include mq(tbl) {
      padding-top: 70px;
      padding-bottom: 80px;
    }
  }
  .inner {
    @include container;
    .list {
      margin-top: 40px;
      @include mq(tbl) {
        @include grid(2,60px,40px);
      }
      .unit {
        display: flex;
        position: relative;
        margin-top: 30px;
        gap: 20px;
        @include mq(tbl) {
          margin-top: 0;
        }
        &:first-child {
          margin-top: 0;
        }
        .image {
          flex: 0 1 auto;
          a {
            display: flex;
            justify-content: center;
            align-items: center;
            // background: #E2F3F8 center / 50% no-repeat url(/images/logomark.svg);
            height: 100px;
            width: 100px;
            @include mq(tbl) {
              height: 160px;
              width: 160px;
              @include hoverOp;
            }
            img {
              max-height: 100px;
              max-width: 100px;
              width: auto;
              aspect-ratio: 1 / 1;
              object-fit: cover;
              border-radius: 5px;
              @include mq(tbl) {
                max-height: 160px;
                max-width: 160px;
              }
              &.default {
                border: 1px solid $border;
              }
            }
          }
        }
        .desc {
          flex: 1;
          .date {
            color: #ccc;
            @include fs(12,12);
            @include mq(tbl) {
              @include fs(13,13);
            }
          }
          .cat {
            margin-top: 10px;
            a {
              display: inline-block;
              margin: 0 5px 5px 0;
              @include fs(10,10);
              border: 1px solid #CCCCCC;
              padding: 4px 10px;
              text-align: center;
              color: #7E7E7E;
              text-decoration: none;
              min-width: 60px;
              font-weight: $font-weight-bold;
              border-radius: 5px;
              @include mq(tbl) {
                @include fs(12,12);
                padding: 5px 10px;
                @include hoverOp;
                min-width: 70px;
              }
            }
          }
          .title {
            @include fs(14,24);
            margin-top: 5px;
            position: relative;
            color: $black;
            @include mq(tbl) {
              @include fs(16,26);
              @include hoverOp;
              margin-top: 10px;
            }
            /*
            &:before {
              @include pd;
              background: center / contain no-repeat url(#{$imagepath}arrow_right_main.svg);
              width: 16px;
              height: 16px;
              top: 12px;
              left: 0;
              transform: translateY(-50%);
              @include mq(tbl) {
                top: 15px;
              }
            }
            */
            a {
              color: inherit;
              text-decoration: none;
            }
          }
          .summary {
            @include fs(11,20);
            margin-top: 6px;
            color: $main-light;
            @include mq(tbl) {
              @include fs(14,24);
            }
          }
        }
      }
    }
    .more {
      margin-top: 40px;
      text-align: center;
    }
  }
}
