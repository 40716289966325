@charset "UTF-8";
@import "variables";
@import "mixins";

.component.type_faq {
  @include topmargin;
  .inner {
    @include container;
    // @include text;
    .unit {
      border-radius: $radius-small;
      margin-top: 30px;
      @include mq(tbl) {
        border-radius: $radius-default;
        margin-top: 20px;
      }
      &:first-of-type {
        margin-top: 0;
      }
      .q {
        background-color: $main-light;
        color: $white;
        padding: 20px 45px 20px 40px;
        @include fs(14,22);
        font-weight: $font-weight-medium;
        border-radius: $radius-small;
        position: relative;
        transition: border-radius $transition-speed $bezier,background $transition-speed $bezier;
        @include mq(tbl) {
          padding: 25px 110px 25px 120px;
          @include fs(16,30);
          cursor: pointer;
          &:hover {
            background-color: lighten($main-light, 10%);
          }
        }
        &.active {
          border-radius: $radius-small $radius-small 0 0;
          @include mq(tbl) {
            border-radius: $radius-default $radius-default 0 0;
          }
          &:after {
            transform: rotate(180deg);
          }
        }
        &:after {
          @include pd;
          width: 10px;
          height: 16px;
          background: center / contain no-repeat url(/images/arrow_open.svg);
          right: 15px;
          top: 50%;
          margin-top: -8px;
          transition: transform $transition-speed-fast $bezier;
          @include mq(tbl) {
            width: 18px;
            height: 26px;
            margin-top: -13px;
            right: 30px;
          }
        }
        &:before {
          @include pd;
          left: 20px;
          content: 'Q.';
          @include fs(17,22);
          @include mq(tbl) {
            @include fs(32,32);
            left: 40px;
            top: 50%;
            transform: translateY(-60%);
          }
        }
      }
      .a {
        padding: 20px 32px 20px 40px;
        border: 1px solid $border;
        @include fs(14,22);
        position: relative;
        display: none;
        @include mq(tbl) {
          padding: 25px 110px 25px 120px;
          @include fs(16,30);
        }
        &:before {
          @include pd;
          left: 20px;
          content: 'A.';
          @include fs(17,22);
          color: $red;
          @include mq(tbl) {
            @include fs(32,32);
            left: 40px;
            top: 26px;
          }
        }
        &:after {
          content: '';
          display: block;
          clear: both;
        }
        img {
          display: block;
          margin: 0 auto 20px !important;
          &.mt-image-right {
            float: none !important;
            @include mq(tbl) {
              float: right !important;
              margin: 0 0 20px 20px !important;
            }
          }
          &.mt-image-left {
            float: none !important;
            @include mq(tbl) {
              float: left !important;
              margin: 0 20px 20px 0 !important;
            }
          }
        }
        
        p {
          padding-bottom: 1.5em;
          &:last-child {
            padding-bottom: 0;
          }
        }
      }
    }
    
  }
}
