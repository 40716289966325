@charset "UTF-8";
@import "variables";
@import "mixins";

footer {
  background-color: #EEEEEE;
  .inner {
    @include container($type:wide);
    .footnav {
      padding-top: 45px;
      @include grid(2,50px,20px);
      @include mq(tbl) {
        padding-top: 60px;
        @include grid(4,60px,15px);
      }
      .unit {
        position: relative;
        h2 {
          @include fs(12,21);
          position: relative;
          font-weight: $font-weight-bold;
          @include mq(tbl) {
            @include fs(14,24);
          }
          + h2 {
            margin-top: 20px;
            @include mq(tbl) {
              margin-top: 20px;
            }
          }
          a {
            color: inherit;
          }
          /*
          &:after, &:before {
            @include pd;
            right: 8px;
            top: 50%;
            background-color: #8E8E8E;
            width: 13px;
            height: 1px;
            @include mq(tbl) {
              display: none;
            }
          }
          &:after {
            width: 1px;
            height: 13px;
            right: 14px;
            margin-top: -6px;
            transition: transform $bezier $transition-speed;
          }
          &.active {
            &:after {
              transform: scaleY(0);
            }
          }
          */
        }
        ul {
          @include fs(12,21);
          padding-top: 10px;
          @include mq(tbl) {
            @include fs(13,21);
            padding-top: 20px;
            display: block !important;
          }
          + h2 {
            @include mq(tbl) {
              margin-top: 40px;
            }
          }
          li {
            a {
              display: block;
              padding: 3px 0;
              position: relative;
              @include mq(tbl) {
                padding: 3px 0;
                @include hoverOp(.3);
              }
              /*
              &:before {
                @include pd;
                height: 1px;
                width: 5px;
                background-color: $black;
                top: 18px;
                left: 20px;
                @include mq(tbl) {
                  left: 0px;
                }
              }
              */
            }
          }
        }
      }
    }
    .footbanner {
      padding-top: 40px;
      @include mq(tbl) {
        padding-top: 50px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
      }
      a {
        background-color: white;
        padding: 15px 25px;
        display: grid;
        grid: "icon text" auto / auto 1fr;
        grid-gap: 16px;
        align-content: center;
        border-radius: $radius-small;
        margin-top: 20px;
        min-height: 60px;
        @include mq(tbl) {
          margin: 0 15px 30px;
          @include hoverOp(.5);
        }
        &:first-child {
          margin-top: 0;
        }
        i {
          grid-area: icon;
          display: flex;
          align-items: center;
          img {
            max-width: 70px;
            max-height: 30px;
          }
        }
        p {
          grid-area: text;
          display: flex;
          align-items: center;
          color: #231815;
          @include fs(14,20);
          font-family: $font-family-sub;
        }
      }
    }
    .footinfo {
      padding-top: 60px;
      @include mq(tbl) {
        padding-top: 80px;
        display: flex;
        justify-content: space-between;
      }
      .corpinfo {
        @include mq(tbl) {
          display: flex;
          align-items: center;
        }
        p {
          text-align: center;
          @include mq(tbl) {
            text-align: left;
          }
          img {
            width: 200px;
          }
        }
        .contact {
          margin-top: 40px;
          text-align: left;
          @include fs(12,24);
          font-weight: bold;
          @include mq(tbl) {
            text-align: left;
            margin-top: 0;
            margin-left: 60px;
            @include fs(13,21);
          }
          address {
            .zip {
              display: block;
              @include mq(tbl) {
                display: inline;
                margin-right: 1em;
              }
            }
          }
          ul {
            display: flex;
            justify-content: center;
            @include mq(tbl) {
              justify-content: flex-start;
            }
            li {
              margin-right: 1em;
              &:after {
                content: '|';
                padding-left: 1em;
              }
              &:last-child {
                margin-right: 0;
                &:after {
                  content: none;
                }
              }
            }
          }
        }
      }
      .sns {
        display: flex;
        justify-content: center;
        gap: 8px;
        margin-top: 40px;
        @include mq(tbl) {
          margin-top: 0;
          gap: 10px;
        }
        li {
          a {
            @include mq(tbl) {
              @include hoverOp(.3);
            }
            img {
              width: 34px;
              @include mq(tbl) {
                width: 34px;
              }
            }
          }
        }
      }
    }
  }
  .copyright {
    margin-top: 70px;
    @include container($type:wide);
    padding-bottom: 30px;
    @include mq(tbl) {
      margin-top: 100px;
      padding-top: 0;
      padding-bottom: 50px;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      flex-direction: row-reverse;
    }
    ul {
      display: flex;
      gap: 15px;
      @include mq(tbl) {
        gap: 50px;
      }
      li {
        flex: 1;
        @include mq(tbl) {
          flex: auto;
        }
        a {
          display: block;
          @include fs(9,18);
          @include mq(tbl) {
            @include hoverOp(.3);
            @include fs(11,21);
          }
        }
      }
    }
    p {
      margin-top: 40px;
      text-align: left;
      letter-spacing: 0;
      @include fs(10,20);
      @include mq(tbl) {
        margin-top: 0;
        text-align: right;
        @include fs(11,20);
        letter-spacing: .02em;
      }
      span {
        display: block;
      }
    }
  }
}

#pagetop {
  position: fixed;
  bottom: 24px;
  right: 18px;
  z-index: 9999;
  cursor: pointer;
  opacity: 0;
  transition: opacity .5s $bezier;
  display: none;
  @include mq(tbl) {
    bottom: 85px;
    right: 30px;
    display: block;
  }
  a {
    transition: opacity .5s $bezier;
    @include mq(tbl) {
      width: 50px;
      height: 50px;
      &:hover {
        opacity: .5;
      }
    }
    img {
      width: 40px;
      height: 40px;
      @include mq(tbl) {
        width: 50px;
        height: 50px;
      }
    }
  }
  &.on {
    opacity: 1;
  }
}
