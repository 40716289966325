@charset "UTF-8";

@import "reset";
@import "variables";
@import "mixins";

* {
   box-sizing: border-box;
}
*:before,
*:after {
   box-sizing: border-box;
}
*:focus {
outline: none;
}
html {
  font-size: 62.5%;
  height: 100%;
}
body {
  @include fs(14);
  color: $body-text-color;
  background-color: $white;
  font-family: $font-family-main;
  font-weight: normal;
  font-feature-settings: 'palt';
  letter-spacing: .02em;
  text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  @if $responsive-debug-mode {
    &:after {
      content: 'min';
      position: fixed;
      left: 10px;
      top: 10px;
      font-size: 12px;
      background-color: red;
      color: white;
      z-index: 1000;
      border-radius: $radius-small;
      display: inline-block;
      padding: 4px;
      @include mq(sp) {
        content: 'sp';
      }
      @include mq(spl) {
        content: 'sp landscape';
      }
      @include mq(tb) {
        content: 'tablet';
      }
      @include mq(tbl) {
        content: 'tablet landscape';
      }
      @include mq(pc) {
        content: 'pc';
      }
    }
  }
  &.fixed {
    position: fixed;
    width: 100%;
  }
}

.red {
  color: $red;
}
strong {
  font-weight: bold;
}
label {
  cursor: pointer;
}
input,select,textarea,label,a {
  outline: none;
}
a {
  color: $link-default;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  &[href^="tel:"] {
    color: inherit;
  }
  &:focus {
    outline: none;
  }
}
abbr {
  text-decoration: none;
}
button {
  padding: 0;
  border: none;
  outline: none;
  font: inherit;
  color: inherit;
  background: none;
  cursor: pointer;
}
sup,
sub {
  height: 0;
  line-height: 1;
  vertical-align: baseline;
  position: relative;
  font-size: 0.75em;
}
sup {
  bottom: .75ex;
}
sub {
  top: .5ex;
}
img {
  // width: 100%;
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
  @include mq(tbl) {
    // width: auto;
  }
}
.hidden-over-tbl {
  @include mq(tbl) {
    display: none;
  }
}
.hidden-over-tb {
  @include mq(tb) {
    display: none;
  }
}
.hidden-over-sp {
  @include mq(sp) {
    display: none;
  }
}
.hidden-sp {
  @media (max-width: 374px) {
    display: none;
  }
}
.hidden-tb {
  @media (max-width: 767px) {
    display: none;
  }
}
.hidden-tbl {
  @media (max-width: 1079px) {
    display: none;
  }
}

.anchor {
  position: relative;
  top: 0;
  display:block;
  visibility:hidden;
  color: transparent;
  &_sub {
    position: relative;
    top: 0;
    display:block;
    visibility:hidden;
    color: transparent;
    @include mq(tbl) {
      top: -100px;
    }
  }
}
.svg_def {
  visibility:hidden;
  width: 0;
  height: 0;
  position: absolute;
}
.container {
  @include container;
}
main {
  transition: opacity .2s $bezier;
  padding-top: $header-height;
  @include mq(tbl) {
    padding-top: $header-height-pc;
  }
  .toppage & {
    padding-top: 0;
  }
  .sink & {
    opacity: .5;
    @include mq(tbl) {
      opacity: 1;
    }
  }
}
#wrapper {
  transition: opacity $transition-speed-fast $bezier;
  
  header + & {
    padding-top: $header-height;
    @include mq(tbl) {
      padding-top: $header-height-pc;
    }
  }
  .toppage & {
    padding-top: 0;
    .contentsInner {
      @include mq(tbl) {
        padding-top: 90px;
      }
    }
  }
  .sink & {
    opacity: .5;
    @include mq(tbl) {
      opacity: 1;
    }
  }
  .contentsInner {
    margin-top: 16px;
    @include mq(tbl) {
      display: flex;
      flex-direction: row-reverse;
      gap: 50px;
      @include container(16px,wide);
      padding-top: 50px;
      padding-bottom: 64px;
      margin-top: 0;
    }
    &:is(.cattitle + *) {
      padding-top: 20px;
      @include mq(tbl) {
        padding-top: 26px;
      }
    }
    main {
      padding-top: unset;
      @include mq(tbl) {
        flex: 1;
      }
      > *:first-child {
        margin-top: 0;
      }
      > *:first-child.anchor + * {
        margin-top: 0;
      }
  
    }
    #sidebar {
      @include mq(tbl) {
        width: 250px;
      }
    }
  }
  
}

//default
@import "header";
@import "footer";
@import "sidebar";
@import "parts";
@import "component/index";
@import "navigation/index";
@import "static/index";
@import "top";
@import "uniq";