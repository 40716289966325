@charset "UTF-8";
@import "variables";
@import "mixins";

.component.type_table {
  @include topmargin(large);
  @include container;
  .inner {
    .table_set {
      position: relative;
      overflow: auto;
      &.type_01 {
        table {
          border: none;
          th, td {
            border: none;
            padding: 0;
          }
        }
      }
      &.type_02 {
        table {

        }
      }
      &.type_03 {
        table {
          th {
            background-color: $sub;
          }
        }
      }
      &.type_04 {
        table {

        }
        th {
          background-color: $main;
          color: $white;
        }
      }
      &.type_05 {
        table {
          th {
            // background-color: $sub;
          }
        }
      }
      &.type_06 {
        table {
          border-left: 1px solid $border;
          tr {
            &:nth-child(odd){
              background-color: $sub;
            }
            &:nth-child(even){
              background-color: $white;
            }
            th, td {
              border-right: 1px solid $border;
            }
          }
        }
      }
      &.type_07 {
        table {
          border-left: 1px solid $border;
          tr {
            &:nth-child(odd){
              background-color: $sub;
            }
            &:nth-child(even){
              background-color: $white;
            }
            th, td {
              font-weight: $font-weight-normal;
              border-right: 1px solid $border;
            }
          }
        }
      }
      &.compact {
        table {
          th,td {
            padding: 4px 10px;
            @include mq(tbl) {
              padding: 12px 15px;
            }
          }
        }
      }
      &.fixed {
        table {
          @include mq(tbl) {
            table-layout: fixed;
          }
        }
      }
      &.fix_left {
        table {
          th {
            position: sticky;
            left: 0;
            /*
            &:before {
              content: '';
              position: absolute;
              left: 0px;
              top: 0;
              bottom:0;
              height: 100%;
              background-color: #E7E5DF;
              width: 2px;
            }
            */
          }
        }
      }
      table {
        border-collapse: collapse;
        border-top: 1px solid $border;
        border-bottom: 1px solid $border;
        width: 100%;
        th,td {
          border-top: 1px solid $border;
          padding: 12px 20px;
          @include fs(14,21);
          min-width: 150px;
          vertical-align: top;
          font-family: $font-family-main;
          @include mq(tbl) {
            @include fs(15,24);
            padding: 18px 35px;
          }
          &.left {
            text-align: left;
          }
          &.center {
            text-align: center;
          }
          &.right {
            text-align: right;
          }
          &.min {
            min-width: auto;
          }
          .p_button {
            margin-top: 15px;
            @include mq(tbl) {
              padding-right: 35px;
            }
            &:before {
              @include mq(tbl) {
                width: 24px;
                height: 24px;
              }
            }
          }
        }
        th {
          font-weight: $font-weight-bold;
          vertical-align: middle;
        }
      }
    }
    .table_overflow {
      padding-top: 30px;
      position: sticky;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      z-index: 1;
      &:before {
        content: '';
        display: block;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 20px auto;
        background-image: url(/images/swipe.svg);
        width: 20px;
        height: 22px;
        margin-left: auto;
        position: absolute;
        right: 0;
        top: 0;
      }
    }
  }
}
