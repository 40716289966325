@charset "UTF-8";
@import "variables";
@import "mixins";

.component.type_extendedtitle {
  position: relative;
  background: center / cover url(/images/extendedtitle_bg.jpg);
  .inner {
    display: flex;
    flex-direction: column-reverse;
    z-index: 3;
    position: relative;
    @include mq(tb) {
      flex-direction: row;
      align-items: center;
    }
    .text {
      padding: 30px 20px;
      text-align: center;
      @include mq(tb) {
        width: 50%;
        text-align: left;
        padding: 20px 50px;
      }
      span {
        display: block;
        padding-bottom: 10px;
        @include fs(14,14);
        color: $white;
        @include mq(tbl) {
          @include fs(18,18);
          max-width: $container-wide / 2;
          margin-left: auto;
        }
      }
      h1 {
        @include fs(26,34);
        font-family: $font-family-sub;
        position: relative;
        color: $white;
        @include mq(tbl) {
          @include fs(42,68);
          max-width: $container-wide / 2;
          margin-left: auto;
        }
      }
    }
    .image {
      @include mq(tb) {
        width: 50%;
      }
      img {
        aspect-ratio: 71 / 40;
        object-fit: cover;
      }
    }
  }
}
