@charset "UTF-8";
@import "variables";
@import "mixins";

.component.type_image_multi {
  @include topmargin(large);
  &.set1 {
    .inner {
      @include mq(tb) {
        justify-content: center;
      }
    }
  }
  &.set2 {
    .inner {
      @include grid(1,40px,0);
      @include mq(tbl) {
        @include grid(2,60px,60px);
      }
    }
  }
  &.set3 {
    .inner {
      @include grid(2,30px,25px);
      @include mq(tbl) {
        @include grid(3,50px,25px);
      }
    }
  }
  &.set4 {
    .inner {
      @include grid(2,30px,25px);
      @include mq(tbl) {
        @include grid(4,40px,20px);
      }
    }
  }
  .inner {
    @include container;
  }
}
