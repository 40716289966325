@charset "UTF-8";
@import "variables";
@import "mixins";

.component {
  strong, b {
    font-weight: $font-weight-bold;
  }
  a {
    color: $main;
    text-decoration: underline;
  }
  .text {
    p {
      @include text;
      &.note {
        @include text(small);
        color: rgba($body-text-color, 0.5);
      }
      &.heading {
        @include fs(15,22);
        font-weight: $font-weight-bold;
        position: relative;
        padding-bottom: 6px;
        margin-bottom: 1em;
        @include mq(tbl) {
          @include fs(20,30);
          padding-bottom: 9px;
          margin-top: .5em;
        }
        &:before {
          @include pd;
          width: 50px;
          height: 1px;
          bottom: 0;
          left: 0;
          background-color: #ccc;
          z-index: 2;
          @include mq(tbl) {
            width: 70px;
          }
        }
      }
      &.figure {
        padding-bottom: 35px;
        @include topmargin(large);
        @include text(small);
        @include mq(tbl) {
          padding-bottom: 65px;
        }
        img {
          &.mt-image-left,
          &.mt-image-center,
          &.mt-image-right,
          &.mt-image-none {
            margin-bottom: 6px !important;
            display: block;
            margin-left: auto;
            margin-right: auto;
            border-radius: 5px;
            @include mq(tbl) {
              margin-bottom: 12px !important;
            }
          }
        }
      }
      img {
        &.mt-image-left,
        &.mt-image-center,
        &.mt-image-right {
          border-radius: 5px;
          margin-bottom: 0 !important;
        }
      }
      &:last-child {
        padding-bottom: 0;
      }
      & + .button{
        margin-top: 0;
      }
    }
    ul, ol {
      @include text;
      &:last-child {
        padding-bottom: 0;
      }
    }
    ul {
      list-style: disc;
      li {
        margin-left: 20px;
      }
    }
    ol {
      list-style: decimal;
      li {
        margin-left: 20px;
      }
    }
    .services {
      list-style: none;
      display: grid;
      @include grid(2,10px,10px);
      @include mq(tbl) {
        @include grid(3,10px,10px);
      }
      li {
        border-radius: $radius-large;
        margin: 0;
        border: 1px solid #DBDBDB;
        padding: 4px 20px 4px 13px;
        display: flex;
        align-items: center;
        @include text(small);
        font-weight: $font-weight-bold;
        &:last-child {
          margin-right: 0;
        }
        i {
          width: 30px;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 7px;
          @include mq(tbl) {
            width: 36px;
            height: 36px;
          }
          img {
            width: 100%;
          }
        }
      }
    }
    .icons {
      display: flex;
      flex-wrap: wrap;
      list-style: none;
      li {
        border-radius: $radius-large;
        margin: 0 10px 10px 0;
        border: 1px solid #DBDBDB;
        padding: 5px 22px;
        font-weight: $font-weight-bold;
        @include text(small);
        @include mq(tbl) {
          padding: 7px 24px;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
    .button {
      margin-top: 10px;
      @include button;
      margin-left: auto;
      @include mq(tbl) {
        margin-top: 15px;
      }
    }
  }
  .image, .movie, .map {
    text-align: center;
    img {
      border-radius: 5px;
      @include mq(tbl) {
        border-radius: 5px;
      }
    }
    .caption {
      text-align: center;
      margin-top: 8px;
      color: $main-light;
      @include text(caption);
      @include mq(tbl) {
        margin-top: 16px;
      }
      &.left {
        text-align: left;
      }
      &.center {
        text-align: center;
      }
      &.right {
        text-align: right;
      }
    }
    .child {
      padding-bottom: 15px;
      @include mq(tbl) {
        padding-bottom: 35px;
      }
      &:last-child {
        padding-bottom: 0;
      }
    }
  }
}

@import "pagetitle";
@import "extendedtitle";
@import "heading";
@import "text";
@import "text_image";
@import "text_map";
@import "map";
@import "image";
@import "image_multi";
@import "movie";
@import "movie_multi";
@import "panel";
@import "speaker";
@import "column";
@import "table";
@import "button";
@import "anchor";
@import "num";
@import "wysiwyg";
@import "catalog";
@import "faq";
@import "member";
@import "iframe";
@import "pagelist";
