@charset "UTF-8";
@import "variables";
@import "mixins";

.navigation.localnav_bottom {
  background-color: $sub;
  @include topmargin(xlarge);
  .inner {
    @include container;
    padding-top: 45px;
    padding-bottom: 50px;
    @include mq(tbl) {
      padding-top: 50px;
      padding-bottom: 50px;
    }
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 30px;
      font-family: $font-family-sub;
      @include mq(tbl) {
        display: block;
        position: relative;
        text-align: center;
        padding-bottom: 30px;
      }
      h2 {
        font-weight: $font-weight-bold;
        @include fs(22,30);
        // padding-right: 25px;
        @include mq(tbl) {
          @include fs(28,34);
        }
      }
      .all {
        @include fs(12,21);
        @include mq(tbl) {
          @include fs(16,16);
          position: absolute;
          right: 0;
          top: 15px;
        }
        a {
          position: relative;
          padding-left: 17px;
          &:before {
            @include pd;
          }
          &:before {
            @include pd;
            background: center / contain no-repeat url(#{$imagepath}arrow_localnav_bottom.svg);
            width: 16px;
            height: 16px;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
          }
        }
      }
    }
    ul {
      @include grid(2,20px,20px);
      @include mq(tbl) {
        @include grid(3,20px,20px);
      }
      li {
        /*
        &.active {
          a {
            @include mq(tbl) {
              border-bottom: 3px solid $main;
            }
            span {
              color: $main-dark;
            }
          }
        }
        */
        a {
          /*
          border-bottom: 1px solid $border;
          padding: 0 0 10px;
          */
          display: block;
          color: inherit;
          @include mq(tbl) {
            padding: 12px 0 16px;
          }
          &:hover {
            @include mq(tbl) {
              border-bottom-color: $main;
              color: $main;
            }
          }
          /*
          i {
            flex: 0 1 auto;
            width: 60px;
            height: 60px;
            background: white center / 50% no-repeat url(/images/logomark.svg);
            margin-right: 15px;
            @include mq(tbl) {
              margin-right: 25px;
            }
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          */
          span {
            display: block;
            position: relative;
            padding-left: 25px;
            font-weight: $font-weight-medium;
            @include fs(13,21);
            @include mq(tbl) {
              @include fs(16,26);
            }
            &:before,&:after {
              @include pd;
            }
            &:before {
              @include pd;
              background: center / contain no-repeat url(#{$imagepath}arrow_localnav_bottom.svg);
              width: 16px;
              height: 16px;
              top: 50%;
              left: 0;
              transform: translateY(-50%);
            }
          }
        }
        &.active,&.current_page_item {
          a {

          }
        }
      }
    }
  }
}
