@charset "UTF-8";

@import "mixins";

#sidebar {
  padding: 24px 16px 64px;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 1004;
  overflow-y: scroll;
  transform: translateY(calc(100% + 150px));
  transition: transform $transition-speed-fast $bezier;
  @include mq(tbl){
    padding: 0;
    position: static;
    width: auto;
    overflow-y: unset;
    transform: translateY(0);
    transition: none;
    z-index: 1;
  }
  &.is-show {
    transform: translateY(0);
  }
  .naire {
    dt {
      padding: 16px;
      font-size: 1.9rem;
      font-weight: bold;
      background-color: $main;
      color: $white;
      text-align: center;
      border-radius: 5px 5px 0 0;
    }
    dd {
      background-color: #F8F8F8;
      border: 1px solid #C5C5C5;
      border-top-width: unset;
      border-radius: 0 0 5px 5px;
      text-align: center;
      padding: 10px;
      .date {
        font-size: 3.2rem;
        font-weight: bold;
      }
      .note {
        font-size: 1.0rem;
        margin-top: 10px;
      }
    }
  }
  .banner {
    margin-top: 20px;
    li {
      margin-top: 10px;
      text-align: center;
      &:first-of-type {
        margin-top: 0;
      }
      img {
        width: 100%;
        filter: drop-shadow(1px 3px 5px rgba(0,0,0,.15));
        max-width: 250px;
      }
    }
    a {
      @include hoverOp;
    }
  }
  .sidebar-header {
    position: relative;
    @include fs(16,20);
    font-weight: bold;
    display: block grid;
    grid-template-columns: auto 1fr;
    gap: 14px;
    margin-top: 55px;
    @include mq(tbl){
    }
    &::before{
      content: '';
      height: 1lh;
      width: 4px;
      background-color: #E35329;
    }
    
  }
  .searchbox {
    margin-top: 22px;
    border: 1px solid #D3D3D3;
    border-radius: 4px;
    transition: border $transition-speed-fast $bezier;
    &:has(input[type="text"]:focus) {
      border-color: $main;
    }
    .searchbox-inner {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      gap: 8px;
      padding-left: 12px;
      padding-right: 8px;
      .icon {
        img {
          width: 23px;
        }
      }
      input[type="text"] {
        border: none;
        padding-top: 12px;
        padding-bottom: 10px;
        width: 100%;
        font-size: 1.6rem;
        flex: 1;
        @include mq(tbl){
          font-size: 1.2rem;
        }
        &:focus {
          outline: none;
        }
      }
    }
  }
  .accordion {
    margin-top: 22px;
    h3 {
      border-top: 1px solid #CCCCCC;
      padding-block: 22px;
      @include fs(13,16);
      color: #000;
      font-weight: bold;
      display: grid;
      grid-template: 
        "title icon" auto
        / 1fr auto;
      align-items: center;
      cursor: pointer;
      @include mq(tbl){
        &:hover {
          color: $main;
        }
      }
      &:last-of-type {
        border-bottom: 1px solid #CCCCCC;
      }
      &::before, &::after {
        content: '';
        background-color: #E35329;
        grid-area: icon;
        width: 12px;
        height: 1px;
      }
      &::after {
        rotate: z 90deg;
        transition: rotate $transition-speed-fast $bezier;
      }
      &.active {
        &::after {
          rotate: z 0deg;
        }
      }
    }
    nav {
      display: none;
      &:last-of-type {
        ul {
          border-bottom: 1px solid #CCCCCC;
          margin-top: -1px;
        }
        
      }
      ul {
        li {
          border-top: 1px solid #CCCCCC;
          a {
            text-align: left;
            display: grid;
            grid-template-columns: auto 1fr;
            align-items: center;
            gap: 22px;
            min-height: 48px;
            padding: 8px 12px;
            @include fs(13,16);
            color: #000;
            @include mq(tbl){
              &:hover {
                color: $main;
              }
            }
            i {
              display: flex;
              width: 40px;
              height: 34px;
              text-align: center;
              align-items: center;
              justify-content: center;
              img {
                max-width: 40px;
                max-height: 34px;
              }
            }
          }
        }
      }
    }

  }
  .features {
    margin-top: 22px;
    ul {
      li {
        border-top: 1px solid #CCCCCC;
        &:last-child {
          border-bottom: 1px solid #CCCCCC;
        }
        a {
          display: block;
          padding-block: 22px;
          @include fs(13,16);
          color: #000;
          font-weight: bold;
          @include mq(tbl){
            &:hover {
              color: $main;
            }
          }
        }
      }
    }
  }
  .categories {
    margin-top: 22px;
    ul {
      li {
        border-top: 1px solid #CCCCCC;
        &:last-child {
          border-bottom: 1px solid #CCCCCC;
        }
        a {
          text-align: left;
          display: grid;
          grid-template-columns: auto 1fr auto;
          align-items: center;
          gap: 22px;
          min-height: 48px;
          padding: 8px 12px;
          @include fs(13,16);
          color: #000;
          @include mq(tbl){
            &:hover {
              color: $main;
            }
          }
          &::after {
            content: '';
            display: block;
            width: 7px;
            height: 12px;
            background-image: url(/images/arrow_sidebar.svg);
            background-size: contain;
            background-position: center;
          }
          i {
            display: flex;
            width: 40px;
            height: 34px;
            text-align: center;
            align-items: center;
            justify-content: center;
            img {
              max-width: 40px;
              max-height: 34px;
            }
          }
        }
      }
    }
  }
  .catalog {
    margin-top: 8px;
    a {
      display: block;
      border: 1px solid var(--color-border);
      background-color: var(--color-surface-weaker);
      border-radius: var(--border-radius-small);
      text-align: center;
      padding: var(--space-medium);
      color: var(--color-text);
      transition: border var(--transition-speed-fast) var(--bezier);
      @include mq(tbl){
        &:hover {
          border-color: var(--color-accent);
        }
      }
      h3 {
        font-weight: var(--font-weight-medium);
        font-size: var(--font-size-large);
        line-height: 120%;
        @include mq(tbl){
          font-size: var(--font-size-medium);
        }
      }
      .image {
        margin-top: 8px;
        img {
          max-width: 200px;
          max-height: 200px;
        }
      }
      p {
        font-size: var(--font-size-xsmall);
        color: var(--color-text-weaker);
        @include mq(tbl){
          font-size: var(--font-size-xxsmall);
        }
      }
    }
  }
  .catalog {
    margin-top: 45px;
    h3 {
      padding: 16px;
      font-size: 2rem;
      font-weight: bold;
      background-color: #E35329;
      color: $white;
      text-align: center;
      border-radius: 5px 5px 0 0;
    }
    .unit {
      border: 2px solid #E6E6E6;
      border-top-width: unset;
      border-radius: 0 0 5px 5px;
      padding: 20px;
      p {
        @include fs(12,20);
      }
      .image {
        text-align: center;
        margin-top: 14px;
        img {
          width: 148px;
        }
      }
      a {
        font-size: 1.3rem;
        margin-top: 20px;
        background-color: #EEEEEE;
        border-radius: 5px;
        display: block grid;
        grid-template-columns: auto 1fr auto;
        gap: 15px;
        padding: 13px 15px;
        transition: background-color $transition-speed-fast $bezier;
        &:hover {
          background-color: #ddd;
        }
        &::before {
          content: '';
          display: block;
          width: 7px;
        }
        &::after {
          content: '';
          display: block;
          width: 7px;
          height: 12px;
          background-image: url(/images/arrow_sidebar_catalog.svg);
          background-size: contain;
          background-position: center;
        }
      }
    }
  }
}
#sidebar-menu-button {
  position: fixed;
  bottom: 32px;
  right: 24px;
  background: top / 64px auto no-repeat url(/images/icon_sidebar.svg);
  width: 64px;
  height: 64px;
  border-radius: 50%;
  z-index: 2000;
  transition: background-color $transition-speed-fast $bezier;
  &.is-active {
    background-position: center bottom;
  }
  @include mq(tbl){
    display: none;
  }
  span {
    display: none;
  }
}