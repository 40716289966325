@charset "UTF-8";
@import "variables";
@import "mixins";

.component.type_text {
  @include topmargin;
  .inner {
    @include container;
    .text {
      @include mq(tbl) {
        padding-left: 95px;
        padding-right: 95px;
      }
    }
  }
  &.lead,
  &.lead_large {
    @include topmargin(large);
    .inner {
      .text {
        @include mq(tbl) {
          padding: 0;
        }
        p {
          @include fs(26,50);
          font-weight: $font-weight-bold;
          @include mq(tbl) {
            @include fs(34,60);
          }
        }
      }
    }
  }
  &.sp_left {
    text-align: left;
  }
  &.sp_center {
    text-align: center;
  }
  &.sp_right {
    text-align: right;
  }
  &.pc_left {
    @include mq(tbl) {
      text-align: left;
    }
  }
  &.pc_center {
    @include mq(tbl) {
      text-align: center;
    }
  }
  &.pc_right {
    @include mq(tbl) {
      text-align: right;
    }
  }
}
