@charset "UTF-8";
@import "variables";
@import "mixins";

.component.type_pagetitle {
  .m_breadcrumbs + & {
    margin-top: 10px;
    @include mq(tbl) {
      margin-top: 18px;
    }
  }
  .contentsInner:has(#sidebar) & .inner {
    padding-top: 0;
  }
  .inner {
    @include container(26px,wide);
    padding-top: 55px;
    position: relative;
    @include mq(tbl) {
      padding-top: 55px;
    }
    .title {
      @include fs(26,38);
      font-family: $font-family-sub;
      position: relative;
      @include mq(tbl) {
        @include fs(32,46);
      }
    }
  }
}
