@charset "UTF-8";
@import "variables";
@import "mixins";

.navigation.breadcrumbs {
  display: none;
  @include mq(tbl) {
    margin-top: 120px;
    padding-bottom: 20px;
    padding-top: 20px;
    display: block;
    border-top: 1px solid $border;
  }
  .m_localnav_bottom + & {
    margin-top: 0;
  }
  .inner {
    @include container;
    ul {
      @include fs(13,18);
      display: flex;
      flex-wrap: wrap;
      li {
        color: #333;
        font-weight: $font-weight-medium;
        position: relative;
        margin-right: 3px;
        &:after {
          content: '\FF0F';
          margin-left: 3px;
          color: #666;
        }
        &:last-child {
          &:after {
            content: none;
          }
        }
        a {
          color: #666;
          @include hoverOp;
          text-decoration: underline;
        }
      }
    }
  }
}
