@charset "UTF-8";
@import "variables";
@import "mixins";

.component.type_image {
  @include topmargin(xlarge);
  .inner {
    @include container;
    .image {
      &.shrink {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}
