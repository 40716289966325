@charset "UTF-8";
@import "variables";
@import "mixins";

.component.type_text_map {
  @include topmargin;
  .inner {
    @include container;
    @include mq(tbl) {
      display: flex;
      justify-content: space-between;
    }
    .text {
      margin-top: 30px;
      @include mq(tbl) {
        width: 45%;
        margin-top: 0;
      }
    }
    .map {
      @include mq(tbl) {
        width: 51%;
        margin-top: 0;
        padding-top: 10px;
      }
      .map_wrap_outer {
        width: 100%;
        &.shrink {
          margin-left: auto;
          margin-right: auto;
        }
        .map_wrap_inner {
          padding-top: 56.25%;
          position: relative;
          display: block;
          width: 100%;
          @include mq(tbl) {
            width: 100%;
          }
          iframe {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            border-radius: 5px;
          }
        }
      }
    }
  }
  &.reverse {
    .inner {
      @include mq(tbl) {
        flex-direction: row-reverse;
      }
    }
  }
}
