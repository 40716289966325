@charset "UTF-8";
@import "variables";
@import "mixins";

.component.type_heading {
  @include topmargin(xxxlarge);
  &.large {
    .inner {
      @include container;
      .heading {
        @include title;
      }
    }
  }
  &.medium {
    .inner {
      @include container;
      .heading {
        @include fs(15,22);
        font-weight: $font-weight-bold;
        position: relative;
        padding-bottom: 6px;
        @include mq(tbl) {
          @include fs(20,30);
          padding-bottom: 9px;
        }
        &:before {
          @include pd;
          width: 50px;
          height: 1px;
          bottom: 0;
          left: 0;
          background-color: #ccc;
          z-index: 2;
          @include mq(tbl) {
            width: 70px;
          }
        }
      }
    }
  }
  .anchor + & {
    // margin-top: 20px;
    @include mq(tbl) {
      // margin-top: 45px;
    }
  }
}
