@charset "UTF-8";
@import "variables";
@import "mixins";

.navigation.pagenate_detail {
  @include topmargin(xlarge);
  @include container;
  .inner {
    padding-top: 40px;
    padding-bottom: 65px;
    position: relative;
    border-top: 1px solid #E6E6E6;
    font-family: $font-family-main;
    display: flex;
    justify-content: space-between;
    @include mq(tbl) {
      padding-top: 60px;
      padding-bottom: 0;
      justify-content: center;
    }
    span {
      display: inline-block;
      @include mq(tbl) {
        min-width: 200px;
      }
      a {
        display: inline-block;
        position: relative;
        background-color: $sub;
        color: $black;
        @include fs(16,28);
        padding: 8px 35px;
        border-radius: $radius-default;
        font-family: $font-family-main;
        @include fs(14,24);
        text-align: center;
        min-width: 120px;
        @include mq(tbl) {
          padding: 10px 35px;
          @include fs(16,28);
          min-width: 180px;
          margin: 0 10px;
          @include hoverOp(.5);
        }
        svg {
          position: absolute;
          right: 18px;
          top: 50%;
          transform: translateY(-50%);
          transform-origin: top center;
          width: 5px;
        }
      }
      &.back {
        position: absolute;
        bottom: 0;
        left: 50%;
        right: auto;
        transform: translateX(-50%);
        @include mq(tbl) {
          position: static;
          transform: translateX(0);
        }
        a {
          padding-left: 10px;
          padding-right: 10px;
        }
      }
      &.prev {
        a {
          svg {
            right: auto;
            left: 18px;
            transform: rotate(180deg) translateY(-50%);
          }
        }
      }
    }
  }
}
