@charset "UTF-8";
@import "variables";
@import "mixins";

/* Slider */

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
  &:focus {
    outline: none;
  }
  &.dragging {
    cursor: pointer;
    cursor: hand;
  }
}

.slick-slider {
  .slick-track, .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
  &:before {
    display: table;
    content: '';
  }
  &:after {
    display: table;
    content: '';
    clear: both;
  }
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir='rtl'] .slick-slide {
  float: right;
}

.slick-slide {
  img {
    display: block;
  }

  &.slick-loading img {
    display: none;
  }

  &.dragging img {
    pointer-events: none;
  }
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}


.static.slider {
  .unit {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    @include mq(tbl) {
      display: block;
      height: 450px;
    }
    @include mq(pc) {
      height: 550px;
    }
    > a {
      display: block;
      position: relative;
      z-index: 1;
    }
    /*
    &::before {
      @include pd;
      @include mq(tbl) {
        z-index: 11;
        width: $container;
        height: 408px;
        background-color: $black;
        opacity: .5;
        transition: opacity .2s ease;
        margin-left: 10px;
      }
      @include mq(pc) {
        width: $container-wide;
        height: 500px;
      }
    }
    */
    .text {
      padding: 17px 20px;
      background-color: rgba(255,255,255,.85);
      position: absolute;
      bottom: 0;
      width: 100%;
      @include mq(tbl) {
        background-color: transparent;
        position: absolute;
        width: 400px;
        left: 150px;
        top: 50%;
        bottom: auto;
        transform: translateY(-50%);
        padding: 0;
        z-index: 10;
      }
      &.right {
        text-align: right;
        @include mq(tbl) {
          left: auto;
          right: 100px;
        }
        .button {
          @include mq(tbl) {
            text-align: right;
          }
        }
      }
      &.center {
        text-align: center;
        @include mq(tbl) {
          text-align: center;
          width: 400px;
          left: 50%;
          transform: translate(-50%,-50%);
        }
        .button {
          @include mq(tbl) {
            text-align: center;
          }
        }
      }
      .textinner {
        display: inline-block;
      }
      h1 {
        @include fs(16,26);
        font-family: $font-family-sub;
        @include mq(tbl) {
          @include fs(26,38);
          color: $white;
        }
        + p {
          margin-top: 5px;
          @include mq(tbl) {
            margin-top: 20px;
          }
        }
      }
      p {
        @include fs(12,20);
        color: $black;
        margin: 0;
        @include mq(tbl) {
          @include fs(14,26);
          color: $white;
          letter-spacing: 2px;
        }
      }
      &.black {
        h1, p {
          @include mq(tbl) {
            color: $black;
          }
        }
      }
      .button {
        margin: 12px 0 0;
        text-align: center;
        border: none;
        @include mq(tbl) {
          margin-top: 25px;
          text-align: left;
        }
        a {
          background-color: $main;
          @include fs(12,12);
          color: $white;
          display: inline-block;
          padding: 9px 20px 11px;
          position: relative;
          border-radius: $radius-small;
          @include mq(tbl) {
            @include fs(13,13);
            padding: 14px 40px;
            @include hoverOp;
          }
        }
        /*
        
        &:before {
          @include pd;
          background: url(#{$imagepath}arrow_right_large.svg);
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          width: 28px;
          height: 16px;
          top: 50%;
          right: 20px;
          transform: translateY(-50%);
        }
        */
      }
    }
    .bg {
      width: 100%;
      height: 540px;
      background-position: center;
      background-size: cover;
      transition: opacity .2s ease;
      @include mq(tbl) {
        display: block;
        height: 450px;
      }
      @include mq(pc) {
        height: 550px;
      }
    }
    &.slick-active::before, &.is-active-next::before {
      opacity: 0;
      z-index: 0;
    }
    .bg.pc {
      display: none;
      @include mq(tbl) {
        display: block;
      }
    }
    .bg.sp {
      display: block;
      @include mq(tbl) {
        display: none;
      }
    }
    &.with_bg {
      &.left {
        @include mq(tbl) {

        }
      }
      &.right {
        @include mq(tbl) {

        }
      }
      .text {
        @include mq(tbl) {
          width: 500px;
        }
        .textinner {
          @include mq(tbl) {
            display: inline-block;
            background-color: rgba(255,255,255,.85);
            padding: 30px 40px;
          }
        }
      }
    }
  }
  .slick-arrow {
    position: absolute;
    top: auto;
    bottom: -9px;
    left: 10px;
    z-index: 10;
    cursor: pointer;
    @include mq(tbl) {
      display: block !important;
      top: 50%;
      bottom: auto;
      height: 60px;
      left: 20px;
      transform: translateY(-50%);
      @include hoverOp(.7);
    }
    @include mq(pc) {

    }
    img {
      width: 40px;
      transform: rotate(180deg);
      @include mq(tbl) {
        width: 60px;
        filter: drop-shadow(0px 0px 0px $main-light);
      }
    }
    &.slick-next {
      left: auto;
      right: 10px;
      @include mq(tbl) {
        left: auto;
        right: 20px;
      }
      @include mq(pc) {
      }
      img {
        transform: rotate(0deg);
      }
    }
  }
  .slick-dots {
    margin: 30px 0;
    @include slickdots(20px,4px);
    @include mq(tbl) {
      display: none !important;
    }
  }
}
