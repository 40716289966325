@charset "UTF-8";
@import "variables";
@import "mixins";

.navigation.newslist {
  @include topmargin;
  .inner {
    @include container;
    ul {
      border-top: 1px solid $border;
      li {
        border-bottom: 1px solid $border;
        a {
          display: block;
          padding: 13px 5px;
          @include mq(tbl) {
            display: flex;
            align-items: flex-start;
            padding: 16px 30px;
            position: relative;
            @include hoverOp(.5);
          }
          .date {
            @include fs(14,14);
            color: $black;
            font-family: $font-family-en;
            @include mq(tbl) {
              @include fs(16,16);
              padding-top: 3px;
            }
          }
          .cat {
            background-color: $main;
            font-weight: $font-weight-bold;
            color: $white;
            @include fs(10,10);
            display: inline-block;
            padding: 4px 10px;
            margin-left: 5px;
            text-align: center;
            @include mq(tbl) {
              position: absolute;
              @include fs(12,12);
              min-width: 120px;
              margin: 0;
              left: 135px;
              padding: 6px 10px;
            }
          }
          h2 {
            margin-top: 10px;
            position: relative;
            // padding-left: 22px;
            @include fs(14,20);
            font-weight: $font-weight-medium;
            color: $black;
            @include mq(tbl) {
              margin: 0 0 0 175px;
              // padding-left: 26px;
              @include fs(16,24);
            }
            /*
            &:before {
              @include pd;
              background: url(#{$imagepath}arrow_right_white.svg);
              width: 16px;
              height: 16px;
              top: 8px;
              left: 0;
              transform: translateY(-50%);
              @include mq(tbl) {
                top: 11px;
              }
            }
            */
          }
        }
      }
    }
  }
}
