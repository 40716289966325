@charset "UTF-8";
@import "variables";
@import "mixins";

.component.type_text_image {
  @include topmargin;
  .inner {
    @include container;
    @include mq(tb) {
      display: flex;
      justify-content: space-between;
    }
    .text {
      margin-top: 30px;
      @include mq(tb) {
        width: 47%;
        margin-top: 0;
      }
    }
    .image {
      @include mq(tb) {
        width: 47%;
        margin-top: 0;
        padding-top: 10px;
      }
    }
  }
  &.reverse {
    .inner {
      @include mq(tb) {
        flex-direction: row-reverse;
      }
    }
  }
}
