@charset "UTF-8";
@import "variables";
@import "mixins";

.static.icon_num {
  @include topmargin(xlarge);
  padding-bottom: 20px;
  .inner {
    @include container;
    @include mq(tb) {
      display: flex;
      justify-content: center;
      gap: 4%;
    }
    .unit {
      margin-top: 30px;
      text-align: center;
      background-color: $sub;
      padding: 15px 15px;
      @include mq(tb) {
        margin-top: 0;
        flex: 1;
        display: flex;
        flex-direction: column;
        padding: 26px 25px;
      }
      &:first-of-type {
        margin-top: 0;
      }
      h3 {
        @include fs(16,21);
        border-bottom: 1px solid #ccc;
        padding-bottom: 15px;
        @include mq(tbl) {
          @include fs(18,24);
          padding-bottom: 20px;
        }
      }
      .icon {
        margin-top: 30px;
        .image {
          @include mq(tb) {
            flex: 1;
          }
          img {
            max-width: 150px;
            max-height: 150px;
          }
        }
        .data {
          margin-top: 20px;
          display: flex;
          justify-content: center;
          align-items: flex-end;
          @include mq(tbl) {
            flex: 1;
          }
          em {
            display: block;
            color: $main;
            font-family: $font-family-sub;
            @include fs(70,70);
            margin: 0 5px;
            @include mq(tb) {
              @include fs(60,60);
            }
            @include mq(tbl) {
              @include fs(74,74);
            }
          }
          span {
            @include fs(18,18);
            display: block;
            transform: translateY(-5px);
            @include mq(tbl) {
              @include fs(22,22);
            }
          }
        }
      }
      .note {
        @include fs(11,20);
        margin-top: 20px;
        text-align: left;
        @include mq(tbl) {
          margin-top: 30px;
          @include fs(14,24);
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}
