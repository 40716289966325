@charset "UTF-8";
@import "variables";
@import "mixins";

header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  height: $header-height;
  background-color: $white;
  transition: transform $transition-speed $bezier, background $transition-speed $bezier;
  &:before {
    @include pd;
    background-color: $white;
    height: $header-height;
    width: 100%;
    z-index: 1002;
    transition: filter $transition-speed $bezier;
    @include mq(tbl){
      height: $header-height-pc;
    }
  }
  @include mq(tbl){
    background-color: $white;
    height: $header-height-pc;
  }
  &.is-scroll {
    &:before {
      filter: drop-shadow(0px 3px 6px rgba(0,0,0,.16));
    }
  }
  &.is-down {
    transform: translateY(-120%);
  }
  .toppage & {
    background-color: $white;
    @include mq(tbl){
      background-color: $white;
    }
  }
  .inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 1003;
    height: $header-height;
    @if $header-store {
      padding-right: $header-store-width;
    }
    @include mq(tbl){
      padding-right: 0;
      justify-content: flex-start;
      height: $header-height-pc;
    }
    #logo {
      position: relative;
      margin-right: auto;
      z-index: 1002;
      padding: 1px 0 0 20px;
      flex-shrink: 0;
      @include mq(tbl){
        padding: 0 20px 0 30px;
        margin-right: 0;
      }
      @include mq(pc){
        padding: 0 32px 0 42px;
      }
      a {
        display: block;
        svg, img {
          &.sp {
            width: 129px;
            transition: transform $transition-speed $bezier;
            transform-origin: left top;
            @include mq(tbl){
              display: none;
            }
          }
          &.pc {
            width: 150px;
            transition: transform $transition-speed $bezier;
            transform-origin: left top;
            display: none;
            @include mq(tbl){
              display: inline-block;
            }
            @include mq(pc){
              width: 173px;
            }
          }
        }
      }
    }
    #gnav {
      display: none;
      position: fixed;
      top: $header-height;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1001;
      background-color: $main-light;
      padding: 22px 26px 40px;
      -webkit-overflow-scrolling: touch;
      overflow-y: auto;
      transform: translateX(100%);
      transition: transform $transition-speed $bezier;
      display: flex;
      flex-direction: column;
      @include mq(tbl){
        position: static;
        display: block;
        padding: 0 10px 0 0;
        background-color: transparent;
        transform: translateX(0);
        overflow: visible;
        align-self: stretch;
      }
      @include mq(pc){
        padding: 0 15px 0 0;
      }
      &.show {
        transform: translateX(0);
      }
      .main {
        @include mq(tbl) {
          display: flex;
          height: 100%;
        }
        li {
          border-bottom: 1px solid #E6E6E6;
          position: relative;
          @include mq(tbl){
            border-bottom: none;
          }
          &.top {
            @include mq(tbl) {
              display: none;
            }
          }
          &.active {
            &:after {
              @include mq(tbl) {
                @include pd;
                left: 10px;
                right: 10px;
                bottom: 0;
                height: 5px;
                background-color: $main;
              }
              @include mq(pc) {
                left: 15px;
                right: 15px;
              }
            }
          }
          a {
            display: block;
            padding: 11px 5px;
            @include fs(16,26);
            color: $white;
            @include mq(spl) {
              padding: 10px 0;
              line-height: 1;
              color: $white;
            }
            @include mq(tbl) {
              padding: 10px 15px;
              line-height: 1;
              font-size: clamp(.1rem, 1.2rem, 1.2rem);
              font-weight: $font-weight-bold;
              color: $black;
              @include hoverOp(0.3);
              height: 100%;
              display: flex;
              align-items: center;
              white-space: nowrap;
            }
            @include mq(pc) {
              padding: 15px clamp(5px, 15px, 15px);
            }
            .toppage & {
              color: $white;
              @include mq(spl) {
                color: $white;
              }
              @include mq(tbl) {
                color: $white;
              }
            }
            span {
              position: relative;
              padding-left: 25px;
              @include mq(tbl){
                padding: 0;
              }
              &:before {
                @include pd;
                background: url(#{$imagepath}arrow_right_white.svg);
                width: 16px;
                height: 16px;
                top: 50%;
                transform: translateY(-50%);
                background-size: contain;
                @include mq(tbl){
                  content: none;
                }
              }
            }
            &.current {
              span {
                opacity: 1;
                @include mq(tbl) {
                  position: relative;
                  opacity: 1;
                  &:after {
                    @include pd;
                    height: 1px;
                    width: 100%;
                    background-color: $main-light;
                    bottom: -3px;
                  }
                }
              }
            }
          }
        }
      }
      /*
      .store, .download {
        margin-top: 34px;
        background-color: $white;
        border-radius: $radius-small;
        padding: 24px 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        @include mq(tbl){
          display: none;
        }
        i {
          margin-right: 20px;
          svg {
            width: 32px;
            fill: $main;
          }
        }
        span {
          color: $main;
          font-size: 1.8rem;
          font-weight: $font-weight-bold;
        }
      }
      */
      .sns {
        margin: 15px 0 0 0;
        display: flex;
        @include mq(spl) {
          margin: 15px 0 0 0;
        }
        @include mq(tbl){
          display: none;
        }
        li {
          margin-right: 20px;
          &:last-child {
            margin-right: 0;
          }
          a {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $white;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            @include mq(spl) {
              width: 20px;
              height: 20px;
            }
            svg {
              width: 20px;
              fill: $white;
              @include mq(spl) {
                width: 20px;
              }
            }
          }
        }
      }
    }
    #lang {
      font-weight: $font-weight-bold;
      @include fs(10,10);
      letter-spacing: 0;
      margin-right: 13px;
      position: relative;
      align-self: stretch;
      display: flex;
      align-items: center;
      cursor: pointer;
      &.active {
        .float {
          opacity: 1;
          pointer-events: all;
        }
      }
      @include mq(tbl) {
        @include fs(11,11);
        margin-right: 21px;
      }
      @include mq(pc) {
        @include fs(13,13);
        margin-right: 20px;
      }
      p {
        padding-left: 15px;
        position: relative;
        @include mq(pc) {
          padding-left: 20px;
        }
        &:before {
          @include pd;
          width: 11px;
          height: 11px;
          left: 0;
          background: no-repeat center / contain url(#{$imagepath}global.png);
          @include mq(tbl) {
            width: 12px;
            height: 12px;
          }
          @include mq(pc) {
            width: 15px;
            height: 15px;
          }
        }
      }
      .float {
        position: absolute;
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
        background-color: white;
        top: 50px;
        left: 50%;
        transform: translateX(-50%);
        opacity: 0;
        pointer-events: none;
        transition: opacity $transition-speed-fast $bezier;
        @include mq(tbl) {
          top: 70px;
        }
        &:before {
          @include pd;
          width: 12px;
          height: 12px;
          position: absolute;
          top: -6px;
          left: 50%;
          margin-left: -6px;
          background-color: $white;
          transform: rotate(45deg);
          box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
        }  
        ul {
          background-color: $white;
          padding: 3px;
          position: relative;
          z-index: 1012;
          text-align: center;
          @include mq(pc) {
            @include fs(12,12);
          }
          li {
            border-top: 1px solid #eee;
            &:first-child {
              border: none;
            }
            a {
              display: block;
              padding: 10px 10px;
              @include mq(tbl) {
                padding: 10px 20px;
                @include hoverOp(.5);
              }
            }
          }
        }
      }
      
    }
    #store, #download {
      height: $header-height;
      width: $header-store-width;
      background-color: $white;
      color: $white;
      padding: 0;
      display: flex;
      text-align: center;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      @include mq(tbl) {
        display: flex;
        height: $header-height-pc;
        padding: 0 20px;
        width: 130px;
        transition: background $transition-speed-fast $bezier;
      }
      @include mq(pc) {
        padding: 0 40px;
      }
      i {
        display: block;
        padding: 0 0 2px;
        @include mq(tbl) {
          padding-bottom: 10px;
        }
        svg {
          width: 24px;
          fill: $white;
          @include mq(tbl) {
            width: 28px;
          }
        }
      }
      span {
        display: block;
        color: $white;
        font-size: 10px;
        line-height: 1.2;
        white-space: nowrap;
        transform: scale(.8);
        @include mq(tbl) {
          @include fs(12,16);
          transform: scale(1);
        }
      }
    }
    #store {
      background-color: #2F4A5B;
      @include mq(tbl) {
        &:hover {
          background-color: lighten(#2F4A5B, 20%);
        }
      }
    }
    #download {
      background-color: #7E7E7E;
      margin-left: auto;
      @include mq(tbl) {
        &:hover {
          background-color: lighten(#7E7E7E, 20%);
        }
      }
    }
    #menuButton {
      position: absolute;
      right: 0;
      top: 0;
      width: $header-height;
      height: $header-height;
      cursor: pointer;
      z-index: 1002;
      background-color: $white;
      @include mq(tbl){
        display: none;
      }
      span {
        background-color: $black;
        width: $menu-bar-width;
        height: 3px;
        border-radius: 5px;
        position: absolute;
        left: ($header-height - $menu-bar-width) / 2;
        &.top {
          top: 19px;
          transition: transform $transition-speed-fast $bezier, background $transition-speed-fast $bezier;
        }
        &.middle, &.back {
          top: 28px;
          transform-origin: center;
          transition: transform $transition-speed-fast $bezier, background $transition-speed-fast $bezier;
        }
        &.bottom {
          top: 37px;
          transition: transform $transition-speed-fast $bezier, background $transition-speed-fast $bezier;
        }
      }
      &.show {
        background-color: $main-light;
        span {
          background-color: $white;
          &.top {
            transform: translateX(50%);
            background-color: transparent;
          }
          &.bottom {
            transform: translateX(-50%);
            background-color: transparent;
          }
          &.middle {
            transform: rotate(45deg) scaleX(1);
            transition: transform $transition-speed-fast $bezier $transition-speed-fast;
          }
          &.back {
            transform: rotate(-45deg) scaleX(1);
          }
        }
      }
    }
  }
}
