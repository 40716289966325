@charset "UTF-8";
@import "variables";
@import "mixins";

.navigation.localnav_sub {
  @include topmargin;
  .inner {
    @include container;
    ul {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      padding-bottom: 5px;
      border-bottom: 1px solid $border;
      @include mq(tbl) {
        padding-bottom: 10px;
      }
      li {
        margin-right: 10px;
        margin-bottom: 10px;
        white-space: nowrap;
        &.active,&.current_page_item {
          a {
            background-color: $main;
            color: white;
            font-weight: $font-weight-bold;
          }
        }
        a {
          display: inline-block;
          background-color: $sub;
          color: $main-light;
          @include fs(12,16);
          padding: 7px 15px;
          border-radius: 20px;
          text-decoration: none;
          @include mq(tbl) {
            @include hoverOp(.5);
            @include fs(13,18);
            padding: 7px 20px;
          }
        }
      }
    }
  }
}
