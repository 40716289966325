@charset "UTF-8";
@import "variables";
@import "mixins";

.component.type_button {
  @include topmargin(large);
  .inner {
    @include container;
    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      li {
        text-align: center;
        margin: 0 10px 20px;
        @include mq(tbl) {
          margin: 0 15px 30px;
        }
        a {
          @include button;
          &[target="_blank"] {
            @include button($size:blank);
          }
        }
      }
    }
  }
  &.cv {
    .inner {
      ul {
        li {
          a {
            @include button($size:apply);
          }
        }
      }
    }
  }
}
