@charset "UTF-8";
@import "variables";
@import "mixins";

.navigation.pagenate {
  @include topmargin(xxlarge);
  @include container;
  .m_newslist + & {
    @include topmargin(large);
    .inner {
      padding-top: 0;
      border-top: none;
    }
  }
  .inner {
    @include fs(12,12);
    @include mq(tbl) {
      @include fs(16,16);
    }
    a, span {
      width: 40px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      display: block;
      border-radius: 50%;
      background-color: $sub;
      color: $black;
      @include mq(tbl) {
        @include hoverOp;
        width: 50px;
        height: 50px;
        line-height: 50px;
      }
      img {
        width: 12px;
        vertical-align: middle;
        translate: 0 -2px;
        @include mq(tbl) {
          width: 16px;
        }
      }
      &.current, &.current_page {
        background-color: $main;
        color: white;
        @include mq(tbl) {
          &:hover {
            opacity: 1;
          }
        }
      }
      &.previouspostslink, &.nextpostslink, &.link_before, &.link_next {
        // background-color: transparent;
      }
      &.previouspostslink, &.link_before {
        img {
          // transform: rotate(180deg);
          transform: scale(-1, 1);
        }
      }
    }
    // wordpress
    .wp-pagenavi {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      a, span {
        margin: 0 3px 15px;

      }
    }
    // mt
    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      li {
        margin: 0 5px 15px;
        @include mq(tbl) {
          margin: 0 10px 20px;
        }
      }
    }
  }
}
