@charset "UTF-8";
@import "variables";
@import "mixins";

.toppage.news {
  padding: 45px 0;
  overflow: hidden;
  background: center / cover no-repeat url(/images/news_bg.jpg);
  @include mq(tbl) {
    padding: 80px 0 50px;
  }
  .inner {
    position: relative;
    @include container(15px,wide);
    .head {
      padding-bottom: 12px;
      position: relative;
      @include mq(tbl) {
        padding-bottom: 20px;
        display: flex;
        gap: 2%;
      }
      .title {
        padding: 0 0 0 3px;
        @include mq(tbl) {
          width: 15%;
        }
        h1 {
          font-family: $font-family-sub;
          color: $white;
          @include fs(20,20);
          @include mq(tbl) {
            @include fs(30,30);
          }
        }
      }
      .navigation.newslist {
        margin-top: 20px;
        @include mq(tbl) {
          margin-top: 0;
          width: 83%;
        }
        .inner {
          padding: 0;
          ul {
            border-color: rgba(230,230,230,.3);
            li {
              border-color: rgba(230,230,230,.3);
              color: $white;
              a {
                .date {
                  color: $white;
                }
                .cat {
                  background-color: $white;
                  color: $main-light;
                }
                h2 {
                  color: $white;
                }
                
              }
            }
          }
        }
      }
    }
    .link {
      margin: 30px 0 0 0;
      text-align: right;
      @include mq(tbl) {
        padding-left: 22px;
        margin-left: 22px;
      }
      a {
        position: relative;
        padding: 6px 43px 0 0;
        display: inline-block;
        color: $white;
        @include fs(15,15);
        @include hoverOp;
        @include mq(tbl) {
          @include fs(16,16);
          padding: 11px 54px 0 0;
        }
        &:before {
          @include pd;
          background: center / contain no-repeat url(#{$imagepath}arrow_right_white_small.svg);
          width: 30px;
          height: 30px;
          top: 0px;
          right: 0;
          @include mq(tbl) {
            width: 40px;
            height: 40px;
          }
        }
      }
    }
  }
}
.toppage.title {
  @include topmargin(xlarge);
  .image {
    position: relative;
    @include container(0, wide);
    img {
      @include sp_pc;
    }
  }
  .text {
    margin-top: -110px;
    position: relative;
    z-index: 2;
    @include container(27px);
    @include mq(tbl) {
      margin-top: -80px;
    }
    .textinner {
      background-color: $white;
      padding: 35px 15px 0;
      text-align: center;
      @include mq(tbl) {
        padding: 60px 45px 0;
      }
      h1 {
        @include fs(24,32);
        font-family: $font-family-sub;
        @include mq(tbl) {
          @include fs(36,48);
        }
      }
      .en {
        @include fs(12,12);
        color: $main-light;
        margin-top: 12px;
        padding-bottom: 17px;
        position: relative;
        @include mq(tbl) {
          @include fs(14,14);
          margin-top: 16px;
          padding-bottom: 24px;
        }
        &:after {
          @include pd;
          width: 70px;
          height: 1px;
          background-color: $main-light;
          left: 50%;
          bottom: 0;
          transform: translateX(-50%);
          @include mq(tbl) {
            width: 80px;
          }
        }
      }
      .lead {
        margin-top: 25px;
        @include fs(22,38);
        font-family: $font-family-sub;
        color: $main;
        @include mq(tbl) {
          @include fs(32,46);
        }
      }
      .paragraph {
        @include fs(14,32);
        margin-top: 30px;
        text-align: left;
        @include mq(tbl) {
          @include fs(15,36);
          text-align: center;
        }
      }
    }
  }
}
.toppage.subtitle {
  @include topmargin(xxlarge);
  .inner {
    position: relative;
    @include container(25px,wide);
    h2 {
      text-align: center;
      @include fs(24,32);
      font-family: $font-family-sub;
      @include mq(tbl) {
        @include fs(28,38);
      }
    }
  }
}
.toppage.panel {
  margin-top: 30px;
  @include mq(tbl) {
    margin-top: 20px;
  }
  &.set2 {
    .inner {
      @include mq(tbl) {
        @include grid(2,60px,20px);
      }
      .unit {
        .title {
          h3 {
            a {
              @include mq(tbl) {
                @include fs(28,34);
              }
            }
          }
        }
        .desc {
          @include mq(tbl) {
            @include fs(15,26);
          }
        }
      }
    }
  }
  &.set3 {
    .inner {
      @include mq(tbl) {
        @include grid(3,50px,20px);
      }
    }
  }
  .inner {
    @include container(0,wide);
    @include mq(tbl) {
      background: linear-gradient(180deg, rgba(255,255,255,1) 20%, rgba(242,244,243,1) 20%);
      padding-bottom: 50px;
    }
    .unit {
      padding: 0 25px 30px;
      background: linear-gradient(180deg, rgba(255,255,255,1) 30%, rgba(242,244,243,1) 30%);
      @include mq(tb) {
        background: none;
        padding: 0;
      }
      .photo {
        margin-top: 30px;
        padding: 10px;
        background-color: rgba(242,244,243,1);
        @include mq(tb) {
          margin-top: 0;
        }
        a {
          @include mq(tbl) {
            @include hoverOp;
          }
        }
        img {
          // border-radius: $radius-small;
          @include mq(tbl) {
            // border-radius: $radius-default;
          }
        }
      }
      .title {
        margin-top: 10px;
        padding: 0 10px;
        @include mq(tbl) {
          margin-top: 15px;
        }
        h3 {
          font-family: $font-family-sub;
          a {
            text-decoration: none;
            color: inherit;
            @include button;
            @include fs(20,30);
            display: block;
            color: $black;
            @include mq(tbl) {
              @include hoverOp;
              @include fs(24,34);
            }
            &:before {
              right: 0;
              width: 30px;
              height: 30px;
              @include mq(tbl) {
                width: 40px;
               height: 40px;
              }
            }
          }
        }
      }
      .desc {
        @include fs(13,24);
        margin-top: 10px;
        padding: 0 10px;
        @include mq(tbl) {
          margin-top: 24px;
          @include fs(14,24);
        }
      }
    }
  }
}

.toppage.text_image {
  @include topmargin(large);
  + .toppage.text_image {
    .inner {
      @include mq(tb) {
        flex-direction: row;
      }
    }
  }
  .inner {
    @include container(25px, wide);
    @include mq(tb) {
      display: flex;
      justify-content: space-between;
      flex-direction: row-reverse;
      align-items: center;
    }
    .text {
      margin-top: 30px;
      @include mq(tb) {
        width: 46%;
        margin-top: 0;
      }
      p {
        padding-bottom: 2em;
        @include fs(14,32);
        &:last-child {
          padding-bottom: 0;
        }
        @include mq(pc) {
          @include fs(17,38);
        }
      }
    }
    .image {
      @include mq(tb) {
        width: 50%;
        margin-top: 0;
        padding-top: 10px;
      }
    }
  }
  &.reverse {
    .inner {
      @include mq(tb) {
        flex-direction: row-reverse;
      }
    }
  }
}
.toppage.button {
  @include topmargin(xlarge);
  .inner {
    @include container;
    a {
      display: block;
      background-color: $main;
      color: white;
      padding: 23px;
      @include fs(16,16);
      font-family: $font-family-sub;
      text-align: center;
      position: relative;
      max-width: 580px;
      margin: 0 auto;
      @include mq(tbl) {
        padding: 30px;
        @include fs(24,24);
        @include hoverOp;
      }
      &:before {
        @include pd;
        background: center / contain no-repeat url(#{$imagepath}arrow_right_white.svg);
        width: 30px;
        height: 30px;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
        @include mq(tbl) {
          width: 40px;
          height: 40px;
        }
      }
    }
  }
}
.toppage.panel_b {
  @include topmargin(xxlarge);
  .inner {
    @include container(0,wide);
    @include grid(2,1px,1px);
    @include mq(tbl) {
      background: linear-gradient(180deg, rgba(255,255,255,1) 30%, rgba(242,244,243,1) 30%);
      padding-bottom: 60px;
      @include grid(4,50px,20px);
    }
    .unit {
      position: relative;
      .photo {
        background-color: black;
        a {
          @include mq(tbl) {
            @include hoverOp;
          }
        }
        img {
          aspect-ratio: 19 / 12;
          object-fit: cover;
          opacity: .6;
          @include mq(tbl) {
            opacity: .8;
          }
        }
      }
      .title {
        @include mq(tbl) {
          margin-top: 20px;
        }
        h3 {
          @include mq(tbl) {
            padding: 0 20px;
          }
          a {
            position: absolute;
            aspect-ratio: 19 / 12;
            width: 100%;
            top: 0;
            z-index: 2;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            @include fs(14,20);
            color: $white;
            @include mq(tbl) {
              @include hoverOp;
              @include fs(17,27);
              @include button;
              color: $black;
              display: block;
              aspect-ratio: unset;
              position: relative;
              text-align: left;
            }
            &:before {
              right: 0;
              width: 30px;
              height: 30px;
              @include mq(tbl) {
                width: 30px;
                height: 30px;
              }
            }
          }
        }
      }
      .desc {
        @include fs(13,24);
        margin-top: 10px;
        padding: 0 10px;
        @include mq(tbl) {
          margin-top: 24px;
          @include fs(14,24);
        }
      }
    }
  }
}
.toppage.articlelist {
  @include topmargin(xlarge);
  background-color: $lightgray;
  .inner {
    @include container(25px, wide);
    padding-top: 50px;
    padding-bottom: 50px;
    @include mq(tbl) {
      padding-top: 80px;
      padding-bottom: 100px;
    }
    > .title {
      text-align: center;
      @include mq(tbl) {
        @include fs(36,48);
      }
      h2 {
        @include fs(24,32);
        font-family: $font-family-sub;
        @include mq(tbl) {
          @include fs(36,48);
        }
      }
      .en {
        @include fs(12,12);
        color: $main-light;
        margin-top: 12px;
        padding-bottom: 17px;
        position: relative;
        @include mq(tbl) {
          @include fs(14,14);
          margin-top: 16px;
          padding-bottom: 24px;
        }
        &:after {
          @include pd;
          width: 70px;
          height: 1px;
          background-color: $main-light;
          left: 50%;
          bottom: 0;
          transform: translateX(-50%);
          @include mq(tbl) {
            width: 80px;
          }
        }
      }
    }
    .lead {
      margin-top: 20px;
      @include fs(22,38);
      font-family: $font-family-sub;
      color: $main;
      @include mq(tbl) {
        @include fs(32,62);
        margin-top: 35px;
      }
    }
    .list {
      margin-top: 40px;
      @include mq(tbl) {
        @include grid(2,70px,40px);
        margin-top: 60px;
      }
      .unit {
        display: flex;
        position: relative;
        margin-top: 30px;
        gap: 20px;
        @include mq(tbl) {
          margin-top: 0;
          padding-left: 20px;
          gap: 40px;
        }
        &:first-child {
          margin-top: 0;
        }
        .cat {
          position: absolute;
          top: -10px;
          left: -10px;
          z-index: 2;
          @include mq(tbl) {
            top: -11px;
            left: 0;
          }
          a {
            display: inline-block;
            margin: 0 5px 5px 0;
            @include fs(10,10);
            background-color: $white;
            border: 1px solid $main-light;
            padding: 4px 10px;
            text-align: center;
            color: $main-light;
            text-decoration: none;
            @include mq(tbl) {
              @include fs(11,11);
              padding: 5px 20px;
              min-width: 110px;
            }
          }
        }
        .image {
          flex: 0 1 auto;
          a {
            display: flex;
            justify-content: center;
            align-items: center;
            // background: #E2F3F8 center / 50% no-repeat url(/images/logomark.svg);
            height: 120px;
            width: 120px;
            @include mq(tbl) {
              height: 180px;
              width: 180px;
              @include hoverOp;
            }
            img {
              max-height: 120px;
              max-width: 120px;
              width: auto;
              @include mq(tbl) {
                max-height: 180px;
                max-width: 180px;
              }
              &.default {
                border: 1px solid $border;
              }
            }
          }
        }
        .desc {
          flex: 1;
          .date {
            color: $main-light;
            @include fs(12,12);
            @include mq(tbl) {
              @include fs(13,13);
            }
          }
          .title {
            @include fs(15,24);
            margin-top: 5px;
            position: relative;
            color: $black;
            @include mq(tbl) {
              @include fs(18,28);
              @include hoverOp;
              margin-top: 10px;
            }
            a {
              color: inherit;
              text-decoration: none;
            }
          }
          .summary {
            @include fs(11,20);
            margin-top: 6px;
            color: $main-light;
            @include mq(tbl) {
              @include fs(14,24);
            }
          }
        }
      }
    }
    .link {
      margin: 30px 0 0 0;
      text-align: right;
      @include mq(tbl) {
        padding-left: 22px;
        margin-left: 22px;
        margin-top: 60px;
      }
      a {
        position: relative;
        padding: 6px 43px 0 0;
        display: inline-block;
        color: $black;
        @include fs(15,15);
        @include hoverOp;
        @include mq(tbl) {
          @include fs(16,16);
          padding: 11px 54px 0 0;
        }
        &:before {
          @include pd;
          background: center / contain no-repeat url(#{$imagepath}arrow_right_main.svg);
          width: 30px;
          height: 30px;
          top: 0px;
          right: 0;
          @include mq(tbl) {
            width: 40px;
            height: 40px;
          }
        }
      }
    }
  }
}
.toppage.originals {
  @include topmargin(large);
  .inner {
    @include container;
    ul {
      @include grid(2,18px,18px);
      @include mq(tb) {
        @include grid(3,18px,18px);
      }
      @include mq(tbl) {
        @include grid(3,60px,18px);
      }
      @include mq(pc) {
        @include grid(4,60px,18px);
      }
      li {
        a {
          display: block grid;
          border: 1px solid #CCCCCC;
          border-left: 12px solid #2F4A5B;
          border-radius: 5px;
          box-shadow: 1px 3px 5px rgba(0,0,0,.15);
          transition: box-shadow $transition-speed-fast $bezier;
          grid-template:
            "name icon" auto
            / auto auto;
          align-items: center;
          justify-content: space-between;
          gap: 15px;
          min-height: 60px;
          padding: 8px 12px;
          @include fs(13,16);
          color: #000;
          @include mq(tbl){
            min-height: 90px;
            padding: 12px 12px;
            &:hover {
              box-shadow: 1px 5px 5px rgba(0,0,0,.2);
            }
          }
          i {
            grid-area: icon;
            display: flex;
            height: 35px;
            text-align: center;
            align-items: center;
            justify-content: center;
            @include mq(tbl){
              height: 60px;
              min-width: 60px;
            }
            img {
              max-width: 44px;
              max-height: 35px;
              @include mq(tbl){
                max-width: 88px;
                max-height: 60px;
              }
            }
          }
          span {
            grid-area: name;
            font-weight: bold;
            @include fs(16,20);
            @include mq(tbl){
              @include fs(18,24);
            }
          }
        }
      }
    }

  }
}
.toppage.obj_01 {
  margin-top: 80px;
  @include mq(tbl) {
    margin-top: 120px;
  }
}
.top_text_image_01 {
  margin-top: 50px !important;
  @include mq(tbl) {
    margin-top: 100px !important;
    .inner {
      align-items: center;
    }
  }
}
.top_text_image_02 {
  margin-top: 100px !important;
  @include mq(tbl) {
    margin-top: 120px !important;
    .inner {
      align-items: center;
    }
  }
}
.navigation.articlelist .link {
  text-align: right;
  margin-top: 25px;
  @include mq(tbl) {
    margin-top: 50px;
  }
  a {
    @include button;
  }
}