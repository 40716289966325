@charset "UTF-8";
@import "variables";
@import "mixins";

.component.type_movie_multi {
  @include topmargin(large);
  &.set2 {
    .inner {
      @include grid(1,40px,0);
      @include mq(tbl) {
        @include grid(2,60px,60px);
      }
    }
  }
  &.set3 {
    .inner {
      @include grid(2,30px,25px);
      @include mq(tbl) {
        @include grid(3,50px,25px);
      }
    }
  }
  &.set4 {
    .inner {
      @include grid(2,30px,25px);
      @include mq(tbl) {
        @include grid(4,40px,20px);
      }
    }
  }
  .inner {
    @include container;
    .movie {
      .movie_wrap_outer {
        width: 100%;
        .movie_wrap_inner {
          padding-top: 56.25%;
          position: relative;
          display: block;
          width: 100%;
          @include mq(tbl) {
            width: 100%;
          }
          iframe {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            border-radius: 5px;
          }
        }
      }
    }
  }
}
