@charset "UTF-8";
@import "variables";
@import "mixins";

.component.type_catalog {
  @include topmargin(large);
  .inner {
    @include container;
    .list {
      margin-top: 30px;
      @include grid(1,30px,0);
      @include mq(tb) {
        @include grid(3,60px,50px);
        padding-bottom: 30px;
        border-bottom: 1px solid #E6E6E6;
      }
      @include mq(tbl) {
        @include grid(3,60px,70px);
      }
      .unit {
        position: relative;
        &:before {
          @include pd();
          @include mq(tb) {
            width: 1px;
            height: 100%;
            right: -25px;
            background-color: #E6E6E6;
          }
          @include mq(tbl) {
            right: -35px;
          }
        }
        &:nth-child(3n){
          &:before {
            content: none;
          }
        }
        .main {
          display: flex;
          gap: 5%;
          @include mq(tb) {
            display: block;
          }
          .image {
            width: 35%;
            @include mq(tb) {
              width: 100%;
            }
            a {
              display: block;
              @include mq(tb) {
                aspect-ratio: 286 / 190;
                display: flex;
                justify-content: center;
                align-items: center;
              }
              @include mq(tbl) {
                @include hoverOp;
              }
            }
            img {
              border-radius: 0;
              @include mq(tb) {
                max-height: 100%;
              }
            }
          }
          .desc {
            width: 60%;
            @include mq(tb) {
              width: auto;
            }
            .title {
              padding-bottom: 0;
              @include fs(15,22);
              @include mq(tbl) {
                @include hoverOp;
                margin-top: 20px;
              }
              a {
                color: $black;
                font-weight: $font-weight-bold;
                position: relative;
                text-decoration: none;
                word-break: break-all;
              }
            }
            .subtitle {
              @include text(caption);
              color: $main-light;
              margin-top: 10px;
              @include mq(tbl) {
              }
            }
            .format {
              @include text(xsmall);
              margin-top: 12px;
              letter-spacing: 0;
              @include mq(tbl) {
                margin-top: 14px;
              }
              img {
                width: 20px;
                height: 22px;
                object-fit: contain;
                margin-right: 8px;
                vertical-align: middle;
              }
              span {
                text-transform: uppercase;
                letter-spacing: 0;
              }
              a {
                color: #666;
                text-decoration: none;
                @include mq(tbl) {
                  @include hoverOp;
                }
              }
            }
          }
        }
        .summary {
          @include fs(12,20);
          margin-top: 12px;
          color: $main-light;
          @include mq(tbl) {
            @include fs(13,22);
            margin-top: 16px;
          }
        }
      }
    }
    .more {
      margin-top: 40px;
      text-align: center;
    }
  }
}
