@charset "UTF-8";
@import "variables";
@import "mixins";

.component.type_speaker {
  @include topmargin(large);
  .inner {
    @include container;
    @include mq(tbl) {
      display: flex;
      justify-content: space-between;
      flex-direction: row-reverse;
      align-items: flex-start;
      gap: 5%;
    }
    .speaker {
      background-color: $main;
      padding: 20px 30px 35px;
      border-radius: $radius-small;
      color: $white;
      @include mq(tbl) {
        padding: 30px 30px 40px;
        width: 30%;
        margin-top: 5px;
        position: sticky;
        top: 0;
        &.static {
          position: static;
        }
      }
      .heading {
        @include fs(16,26);
        text-align: center;
        font-weight: $font-weight-bold;
        margin-bottom: 10px;
        @include mq(tbl) {
          @include fs(18,28);
          margin-bottom: 15px;
        }
      }
      .unit {
        + .unit {
          margin-top: 30px;
          @include mq(tbl) {
            margin-top: 35px;
          }
        }
        .photo {
          text-align: center;
          padding-bottom: 20px;
          img {
            /*
            max-width: 160px;
            border-radius: 50%;
            */
          }
        }
        .position {
          @include fs(12,18);
          text-align: center;
          padding-bottom: 4px;
          @include mq(tbl) {
            @include fs(14,22);
          }
        }
        .name {
          text-align: center;
          dt {
            margin-bottom: 2px;
            @include fs(18,26);
            font-weight: $font-weight-bold;
            @include mq(tbl) {
              margin-bottom: 2px;
              @include fs(22,30);
            }
            &:last-child {
              margin-bottom: 0;
            }
          }
          dd {
            @include fs(11,18);
            margin-bottom: 12px;
            font-weight: $font-weight-bold;
            color: #EBEBEB;
            @include mq(tbl) {
              margin-bottom: 18px;
              @include fs(12,18);
            }
          }
        }
        .profile {
          border-top: 1px solid $white;
          margin-top: 10px;
          @include fs(12,18);
          padding-top: 19px;
          @include mq(tbl) {
            @include fs(13,22);
          }
          &.en {
            border: none;
            padding-top: 0;
          }
        }
      }
    }
    .text {
      margin-top: 40px;
      @include mq(tbl) {
        width: 65%;
        margin-top: 0;
      }
      .type_movie {
        margin-top: 20px;
        @include mq(tbl) {
          margin-top: 20px;
          padding-bottom: 40px;
        }
        .container {
          padding: 0;
          display: block;
        }
      }
      .type_column,
      .type_sub {
        margin-top: 15px;
        padding-bottom: 20px;
        @include mq(tbl) {
          margin-top: 20px;
          padding-bottom: 40px;
        }
        .container {
          padding: 0;
          display: block;
          .inner {
            .text {
              margin-top: 0;
              width: 100%;
            }
          }
        }
      }
      .type_table {
        margin-top: 15px;
        padding-bottom: 20px;
        padding-left: 0;
        padding-right: 0;
        @include mq(tbl) {
          margin-top: 20px;
          padding-bottom: 40px;
        }
        .inner {
          padding: 0;
          display: block;
          .table_set {
            table {
              th,td {
                @include fs(13,26);
                padding: 8px 10px;
                @include mq(tbl) {
                  @include fs(16,32);
                  padding: 10px 20px;
                }
              }
            }
            &.compact {
              table {
                th,td {
                  @include fs(13,18);
                  padding: 4px 5px;
                  @include mq(tbl) {
                    @include fs(16,22);
                    padding: 10px 10px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
