@charset "UTF-8";
@import "variables";
@import "mixins";

.static.image_link {
  @include topmargin(large);
  padding-bottom: 35px;
  @include mq(tb) {
    padding-bottom: 0;
  }
  .inner {
    @include container;
    .unit {
      background-size: 90% auto;
      background-position: top left;
      background-repeat: no-repeat;
      padding: 150px 0 0 45px;
      @include mq(tb) {
        background-size: 69% auto;
        padding: 0 0 0 61%;
        position: relative;
        min-height: 350px;
      }
      @include mq(tbl) {
        min-height: 505px;
      }
      .box {
        background-color: $white;
        border-left: 1px solid $black;
        transform: translateY(30px);
        position: relative;
        padding: 25px 0 5px 25px;
        @include mq(tb) {
          transform: translateY(0);
          position: absolute;
          right: 0;
          bottom: 0;
          width: 380px;
          padding: 30px 10px 20px 30px;
        }
        &:before {
          @include pd;
          top: 0;
          left: 0;
          height: 1px;
          width: 100%;
          background-color: $black;
          @include mq(tb) {
            width: 160px;
          }
        }
        h3 {
          font-family: $font-family-sub;
          @include fs(22,32);
          @include mq(tbl) {
            @include fs(28,38);
          }
        }
        p {
          @include text(small);
          margin-top: 10px;
          @include mq(tbl) {
            margin-top: 20px;
          }
        }
        ul {
          margin-top: 15px;
          @include mq(tbl) {
            margin-top: 20px;
            padding-left: 10px;
          }
          li {
            @include text(small);
            margin-top: 8px;
            @include mq(tbl) {
              margin-top: 15px;
            }
            a {
              position: relative;
              display: block;
              padding-left: 20px;
              color: $main;
              text-decoration: underline;
              @include mq(tbl) {
                padding-left: 24px;
                &:hover {
                  text-decoration: none;
                }
              }
              &:before {
                @include pd;
                left: 0;
                top: 3px;
                background: center / contain no-repeat url(#{$imagepath}arrow_right_white.svg);
                width: 13px;
                height: 13px;
                @include mq(tbl) {
                  width: 16px;
                  height: 16px;
                  top: 6px;
                }
              }
            }
          }
        }
        .link {
          margin-top: 10px;
          text-align: right;
          @include mq(tbl) {
            margin-top: 20px;
          }
          a {
            @include button;
          }
        }
      }
    }
  }
}
