@charset "UTF-8";
@import "variables";
@import "mixins";

.component.type_member {
  @include topmargin(large);
  .inner {
    @include container;
    @include grid(1,30px,0);
    @include mq(sp) {
      @include grid(2,26px,26px);
    }
    @include mq(tbl) {
      @include grid(3,40px,40px);
    }
    .unit {
      background-color: $sub;
      .photo {
        margin-bottom: 20px;
        img {
          aspect-ratio: 3 / 2;
          object-fit: cover;
        }
      }
      .info {
        margin-top: 20px;
        padding: 0 20px;
        @include mq(tbl) {
          padding: 0 30px;
        }
        .position {
          @include fs(13,18);
          color: $main;
          text-align: center;
          @include mq(tbl) {
            @include fs(14,21);
            margin-bottom: 3px;
          }
        }
        h3 {
          @include fs(20,30);
          color: $black;
          font-weight: $font-weight-bold;
          text-align: center;
          @include mq(tbl) {
            @include fs(22,30);
          }
        }
        .profile {
          color: $main-light;
          @include fs(12,16);
          margin-top: 10px;
          @include mq(tbl) {
            @include fs(12,20);
            margin-top: 15px;
          }
        }
      }
      .desc {
        border-top: 1px solid $border;
        @include fs(13,22);
        margin: 20px 20px 0;
        padding: 15px 0 20px;
        @include mq(tbl) {
          margin: 20px 30px 0;
          @include fs(14,24);
        }
        p {
          padding-bottom: 1em;
          &:last-child {
            padding-bottom: 0;
          }
        }
      }
    }
  }
}
