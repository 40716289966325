@charset "UTF-8";
@import "variables";
@import "mixins";

.static.heading_link {
  @include topmargin(large);
  &.set2 {
    .inner {
      @include mq(tbl) {
        display: flex;
        gap: 6%;
      }
      .unit {
        @include mq(tbl) {
          flex: 1;
        }
        ul {
          @include mq(tbl) {
            column-count: 1;
          }
        }
      }
    }
  }
  .inner {
    @include container;
    .unit {
      + .unit {
        @include topmargin(large);
        @include mq(tbl) {
          margin-top: 0;
        }
      }
      h3 {
        font-family: $font-family-sub;
        @include fs(22,32);
        @include mq(tbl) {
          @include fs(28,38);
        }
      }
      p {
        @include text(small);
        margin-top: 15px;
        @include mq(tbl) {
          margin-top: 30px;
        }
      }
      ul {
        margin-top: 15px;
        border-top: 1px solid $border;
        border-bottom: 1px solid $border;
        padding: 15px 0;
        @include mq(tbl) {
          padding: 30px 0;
          margin-top: 30px;
          column-count: 3;
          column-rule: solid 1px $border;
          column-gap: 7%;
        }
        li {
          @include text(small);
          margin-top: 12px;
          @include mq(tbl) {
            margin-top: 0;
          }
          a {
            position: relative;
            display: block;
            padding-left: 20px;
            color: $main;
            text-decoration: underline;
            @include mq(tbl) {
              padding: 10px 0 10px 24px;
              &:hover {
                text-decoration: none;
              }
            }
            &:before {
              @include pd;
              left: 0;
              top: 3px;
              background: center / contain no-repeat url(#{$imagepath}arrow_right_white.svg);
              width: 13px;
              height: 13px;
              @include mq(tbl) {
                width: 16px;
                height: 16px;
                top: 16px;
              }
            }
          }
        }
      }
      .link {
        margin-top: 15px;
        text-align: right;
        @include mq(tbl) {
          margin-top: 30px;
        }
        a {
          @include button(small);
        }
      }
    }
  }
}
